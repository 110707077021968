import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { TextControl } from '@clatter/ui';
import { debounce } from 'lodash';
import styled from 'styled-components';

const StyledFiltersContainer = styled.div`
  width: 80vw;

  @media (min-width: 769px) {
    width: 100%;
    min-width: 350px;
  }
`;

export const AssetUsageFilters = ({ disabled, onFiltersChange }) => {
  const { control, handleSubmit, watch, getValues } = useFormContext();

  useEffect(() => {
    handleSubmit(onFiltersChange(getValues()));
  }, [handleSubmit]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onFiltersChange)());
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmitDebounced = useCallback(
    debounce(({ onChange, search }) => onChange(search), 300),
    [],
  );

  return (
    <form onSubmit={handleSubmit(onFiltersChange)}>
      <DevTool control={control} placement="bottom-left" />

      <StyledFiltersContainer>
        <Controller
          name="search_query"
          control={control}
          render={({ field: { onChange, onBlur } }) => (
            <TextControl
              placeholder="Search..."
              fullWidth
              disabled={disabled}
              onBlur={onBlur}
              data-test-id="search-input"
              onChange={(event) =>
                onSubmitDebounced({
                  onChange,
                  search: event.currentTarget.value,
                })
              }
              text="Search"
            />
          )}
        />
      </StyledFiltersContainer>
    </form>
  );
};
