import React from 'react';
import styled from 'styled-components';

const StyledLoader = styled.div`
  position: relative;
  line-height: 1;
  width: 100%;

  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;
    justify-content: center;
    align-items: center;
    background-color: rgba(17, 17, 17, 0.36);
    line-height: 1;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
    }

    .loader {
      font-size: 10px;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ffffff;
      transform: translateZ(0);
      animation: load8 1.1s infinite linear;
    }
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const ContentLoader = ({ children, isLoading = false }) => {
  return (
    <StyledLoader>
      {isLoading && (
        <div className="loading-wrapper">
          <div className="loader" />
        </div>
      )}
      {children}
    </StyledLoader>
  );
};

export default ContentLoader;
