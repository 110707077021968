import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { Drawer } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import StyledCloseButton from '../CircleButton/CircleButton';

const StyledDialogContent = styled.div`
  padding: 20px;
  margin: 20px;
  min-width: 300px;
  width: 40vw;

  ${StyledCloseButton} {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9999;
  }
`;

const SideDialog = ({ children, isOpen, onClose }) => {
  return ReactDOM.createPortal(
    <Drawer anchor="right" open={isOpen}>
      <StyledDialogContent>
        <StyledCloseButton onClick={onClose} type="button">
          <CloseIcon />
        </StyledCloseButton>
        {children}
      </StyledDialogContent>
    </Drawer>,
    document.body,
  );
};

export default SideDialog;
