import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { platformSelectors } from '@clatter/platform';

export const useGetToolsData = () => {
  const toolsNames = useSelector(platformSelectors.tools.selectToolsNamesMap());

  const getToolNameBySlug = useCallback(
    (slug) => {
      switch (slug) {
        case 'pm-sl':
          const pmToolName = toolsNames?.pm || toolsNames?.pg;
          const slToolName = toolsNames?.sl;

          // (pm|pg) & sl
          if (pmToolName && slToolName) {
            return `${pmToolName} & ${slToolName}`;
          }

          // (pm|pg)
          if (pmToolName && !slToolName) {
            return pmToolName;
          }

          // sl
          if (slToolName && !pmToolName) {
            return slToolName;
          }

          break;
        default:
          return toolsNames[slug === 'pm' ? 'pg' : slug] || 'N/A';
      }
    },
    [toolsNames],
  );

  return {
    getToolNameBySlug,
    toolsNames,
  };
};
