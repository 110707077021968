import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { LicenseInfo } from '@mui/x-license-pro';

import Loader from '../../components/Loader/Loader';
import { AuthState } from '@clatter/platform';

LicenseInfo.setLicenseKey(process.env.NX_MUI_LICENSE_KEY);

const RootProvider = ({ store, App, usePersistence = true }) => {
  const persistor = usePersistence ? persistStore(store) : null;

  const content = (
    <StrictMode>
      <BrowserRouter>
        <AuthState>
          <App />
        </AuthState>
      </BrowserRouter>
    </StrictMode>
  );

  // 'home' app is not using persist gate...
  return (
    <Provider store={store}>
      {usePersistence ? (
        <PersistGate loading={<Loader />} persistor={persistor}>
          {content}
        </PersistGate>
      ) : (
        content
      )}
    </Provider>
  );
};

export const renderApp = (store, App, rootElementId = 'root', usePersistence = true) => {
  ReactDOM.render(
    <RootProvider store={store} App={App} usePersistence={usePersistence} />,
    document.getElementById(rootElementId),
  );
};
