import styled from 'styled-components';

const IconButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: ${({ theme }) => theme.zoomControls.buttonColor};

  &:disabled {
    color: ${({ theme }) => theme.zoomControls.buttonDisabledColor};
  }
`;

export default IconButton;
