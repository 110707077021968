import React from 'react';
import { Stack } from '@mui/material';
import IconButton from './IconButton';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.viewControls.color};
  &.active > .icon span {
    background-color: ${({ theme }) => theme.viewControls.activeColor};
  }
`;

const StyledThumbnailsIcon = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${({ gap }) => gap ?? '3px'};
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};
  grid-column-gap: ${({ gap }) => gap ?? '3px'};

  span {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.viewControls.color};
  }
`;

const StyledListIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: ${({ gap }) => gap ?? '3px'};
  width: ${({ size }) => size ?? '20px'};
  height: ${({ size }) => size ?? '20px'};

  span {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.viewControls.color};
  }
`;

const ThumbnailsIcon = () => (
  <StyledThumbnailsIcon className="icon">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </StyledThumbnailsIcon>
);

const ListIcon = () => (
  <StyledListIcon className="icon">
    <span></span>
    <span></span>
    <span></span>
  </StyledListIcon>
);

const ViewToggle = ({ activeView, onChange }) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <StyledIconButton
        type="button"
        title="Thumbnail"
        onClick={() => onChange('thumbnail')}
        className={classNames({ active: activeView === 'thumbnail' })}
      >
        <ThumbnailsIcon />
      </StyledIconButton>

      <StyledIconButton
        type="button"
        title="List"
        onClick={() => onChange('list')}
        className={classNames({ active: activeView === 'list' })}
      >
        <ListIcon />
      </StyledIconButton>
    </Stack>
  );
};

export default ViewToggle;
