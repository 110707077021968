import React from 'react';

export const assetUsageViewTypes = {
  THUMBNAIL: 'thumbnail',
  LIST: 'list',
};

export const assetUsageReportAssetTypes = {
  SLIDE: 'slide',
  BIOS: 'bios',
  FILES: 'files',
  VIDEOS: 'videos',
  LINKS: 'links',
  TOOL: 'tool',
  ASSET_TYPE: 'type',
};

// TOOL and ASSET_TYPE are not exactly an asset types, but to make this filtering
// a generic and reuse in different contexts will add them here, maybe we should consider
// renaming this method...
export const fieldsToFilterBy = {
  [assetUsageReportAssetTypes.TOOL]: ['title', 'count'],
  [assetUsageReportAssetTypes.ASSET_TYPE]: ['title', 'slug', 'count'],
};
