const routes = {
  index: '/',
  logout: '/logout',
  users: '/users',
  addUser: '/users/add',
  editUser: '/users/:userId',
  reports: '/reports/:reportName?',
  dashboard: '/dashboard',
  featureFlags: '/feature-flags',
  systemConfiguration: '/system-configuration',
  analytics: '/analytics',
  reportUsersLoginsDetails: '/reports/users-logins-details',
  reportActiveUsers: '/reports/active-users',
  reportFailedLogins: '/reports/failed-logins',
  reportFailedLoginsDetails: '/reports/failed-logins-details',
  reportAssetUsage: '/reports/asset-usage',
  reportAssetUsageToolDetails: '/reports/asset-usage/tools/:slug',
  reportAssetUsageToolAssetDetails: '/reports/asset-usage/tools/:toolSlug/assets/:assetSlug',
  reportAssetUsageAssetDetails: '/reports/asset-usage/tools/:toolSlug/assets/:assetSlug/details/:assetItemId',
  reportUnusedAsset: '/reports/unused-asset',
  reportUnusedAssetToolDetails: '/reports/unused-asset/tools/:slug',
  reportUnusedAssetToolAssetDetails: '/reports/unused-asset/tools/:toolSlug/assets/:assetSlug',
  reportUnusedAssetAssetDetails: '/reports/unused-asset/tools/:toolSlug/assets/:assetSlug/details/:assetItemId',
  reportPageViews: '/reports/page-views',
  reportUtilizationDashboard: '/reports/utilization-dashboard',
  reportUnauthorizedUserAccessAttempts: '/reports/unauthorized-user-access-attempts',
  reportUnauthorizedUserAccessAttemptsDetails: '/reports/unauthorized-user-access-attempts-details',
  reportSlidesDownloads: '/reports/slides-downloads',
  userCustomAttributes: '/users/custom-attributes',
};

export const documentTitleMap = {
  dashboard: 'Dashboard',
  users: 'Users',
  analytics: {
    document: 'Reports: Analytics',
    page: 'Analytics',
  },
  userCustomAttributes: 'User Custom Attributes',
  addUser: 'Users:Add User',
  editUser: 'Users:Edit User',
  reportUtilizationDashboard: {
    document: 'Reports:Utilization Dashboard',
    page: 'Utilization Dashboard',
  },
  reportAssetUsage: {
    document: 'Reports:Asset Usage',
    page: 'Asset Usage',
  },
  reportUnusedAsset: {
    document: 'Reports:Unused Asset',
    page: 'Unused Asset',
  },
  reportPageViews: {
    document: 'Reports:Page Views',
    page: 'Page Views',
  },
  reportPlausiblePageViews: {
    document: 'Reports:Page Views',
    page: 'Plausible Page Views',
  },
  reportActiveUsers: {
    document: 'Reports:Active Users',
    page: 'Active Users',
  },
  reportUsersLogins: {
    document: 'Reports:Users Logins',
    page: 'Users Logins',
  },
  reportUsersLoginsDetails: {
    document: 'Reports:Users Logins Details',
    page: 'Users Logins Details',
  },
  reportFailedLogins: {
    document: 'Reports:Failed Logins',
    page: 'Failed Logins',
  },
  reportFailedLoginsDetails: {
    document: 'Reports:Failed Logins Details',
    page: 'Failed Logins Details',
  },
  reportUnauthorizedUserAccessAttempts: {
    document: 'Reports:Unauthorized User Access Attempts',
    page: 'Unauthorized User Access Attempts',
  },
  reportUnauthorizedUserAccessAttemptsDetails: {
    document: 'Reports:Unauthorized User Access Attempts Details',
    page: 'Unauthorized User Access Attempts Details',
  },
  reportSlidesDownloads: {
    document: 'Reports:Slides Downloads',
    page: 'Slides Downloads',
  },
  featureFlags: 'Feature Flags',
  systemConfiguration: 'System Configuration',
};

export default routes;
