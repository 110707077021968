import { getAxiosApiV2WithAuthorization } from '@clatter/platform';
import queryString from "query-string";

const assetUsageApi = {
  fetchAssetTypesForTool: ({ toolSlug }) => {
    return getAxiosApiV2WithAuthorization({
      url: `/assets/tools/${toolSlug}/asset-types`,
      method: 'get',
    });
  },
  fetchAssetTypeStruct: ({ toolSlug, assetSlug }) => {
    return getAxiosApiV2WithAuthorization({
      url: `/assets/tools/${toolSlug}/asset-types/${assetSlug}`,
      method: 'get',
    });
  },
  fetchAssetReportData: ({ query }) => {
    return getAxiosApiV2WithAuthorization({
      url: queryString.stringifyUrl({
        url: `/assets`,
        query: query,
      }),
      method: 'get',
      query: query
    });
  },
  fetchToolsWithAssets: () => {
    return getAxiosApiV2WithAuthorization({
      url: '/assets/tools',
      method: 'get',
    });
  },
};

export default assetUsageApi;
