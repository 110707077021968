import React, { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { startCase } from 'lodash';

import { useDeepCompareMemo } from '@clatter/platform';
import { PageTitleHeader} from '../index';

const getMicrositeBreadcrumbsLinks = ({ micrositeId, micrositeName, pageName, routes, isAdmin }) => {
  if (isAdmin) {
    return [
      {
        underline: 'hover',
        color: 'inherit',
        url: routes.admin,
        label: 'Admin',
      },
    ]
  }

  if (!micrositeName) {
    return [];
  }

  const links = [
    {
      underline: 'hover',
      color: 'inherit',
      url: generatePath(routes.siteSettings, { siteId: micrositeId }),
      label: startCase(micrositeName),
    },
  ];

  if (pageName) {
    links.push({
      underline: 'hover',
      color: 'inherit',
      url: '',
      disabled: true,
      label: startCase(pageName),
    });
  }

  return links;
};

export const usePageTitleHeader = ({ currentMicrosite, currentPage, noMargin, customLinks, pageTitle, routes, linksType }) => {
  const breadcrumbsLinks = useDeepCompareMemo(() => {
    if (customLinks || Array.isArray(customLinks)) {
      return customLinks
    }

    if (currentMicrosite || currentPage || linksType === 'msm-admin') {
      return getMicrositeBreadcrumbsLinks({
        micrositeName: currentMicrosite?.name || currentPage?.title,
        micrositeId: currentMicrosite?.id || currentPage?._id,
        pageName: currentPage?.name || currentPage?.title,
        routes: routes,
        isAdmin: linksType === 'msm-admin',
      });
    }

    return [];
  }, [currentMicrosite, currentPage, routes, customLinks, linksType]);

  // -- props:
  // headerActions: [react element],
  // secondaryContent: [react element],
  // type: ['ribbon', null]}
  // -- if type is 'ribbon'
  // ribbonActions: [react element]
  // text: [string]
  // disableDivider: [boolean]
  //
  // NOTE: support for ribbon is preserved to stay consistent
  // with previous styling
  const renderPageTitleHeader = useCallback(
    (props) => <PageTitleHeader pageTitle={pageTitle} links={breadcrumbsLinks} noMargin={noMargin} {...props} />,
    [pageTitle, breadcrumbsLinks, noMargin],
  );

  return {
    renderPageTitleHeader: renderPageTitleHeader,
  };
};
