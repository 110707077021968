import React from 'react';
import { Slider, Stack, Box } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import styled from 'styled-components';
import IconButton from './IconButton';

const StyledSlider = styled(Slider)`
  color: ${({ theme }) => `${theme.zoomControls.color} !important`};
  .MuiSlider-rail {
    background-color: ${({ theme }) => theme.zoomControls.backgroundColor};
  }
  .MuiSlider-thumb {
    &:hover {
      box-shadow: ${({ theme }) => theme.zoomControls.sliderHoverShadow};
    }

    &.Mui-active {
      box-shadow: ${({ theme }) => theme.zoomControls.sliderActiveShadow};
    }
  }
`;

const ZoomControls = ({ onChange, value, step, min = 0, max = 100 }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Box sx={{ width: 200 }}>
      <Stack spacing={2} direction="row" alignItems="center">
        <IconButton
          type="button"
          title="Zoom Out"
          disabled={value <= min}
          onClick={() => onChange(value - step)}
        >
          <RemoveIcon />
        </IconButton>
        <StyledSlider
          min={min}
          max={max}
          step={step}
          value={value}
          aria-label="Zoom"
          onChange={handleChange}
        />
        <IconButton
          type="button"
          title="Zoom In"
          disabled={value >= max}
          onClick={() => onChange(value + step)}
        >
          <AddIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ZoomControls;
