import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { TextControl } from '@clatter/ui';
import { useForm, useWatch } from 'react-hook-form';
import { debounce } from 'lodash';

const StyledTextControl = styled.div`
  input {
    max-width: 400px;
  }
`;

const TableSearchFilter = ({ onChange, timeout = 300 }) => {
  const { handleSubmit, register, control } = useForm({
    mode: 'onChange',
    defaultValues: { search: '' },
  });
  const searchWatch = useWatch({ control, name: 'search' });

  const onSubmitDebounced = useCallback(
    debounce(({ search }) => onChange(search), timeout),
    [],
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      handleSubmit(onSubmitDebounced)();
    }

    return () => mounted = false;
  }, [searchWatch]);

  return (
    <StyledTextControl>
      <TextControl
        {...register('search')}
        placeholder="Search..."
        data-test-id="table-search-input"
      />
    </StyledTextControl>
  );
};

export default TableSearchFilter;
