import React, { useState } from 'react';
import { FormControl, SelectControl } from '../../../index';
import { Box } from '@mui/material';

const ToolsSelector = ({
  label,
  isMulti,
  options,
  disabled,
  onChange,
  maxWidth,
  labelMinWidth,
  getChipColors,
  selectedTools,
  placeholder = 'Select...',
  defaultOptionValue = 'all',
  ...props
}) => {
  const [defaultOptionSelected, setDefaultOptionSelected] = useState(true);
  const handleToolsChange = (selectedOptions) => {
    if (Array.isArray(selectedOptions)) {
      if (
        selectedOptions.length === 0 ||
        (!defaultOptionSelected &&
          selectedOptions?.some((item) => item?.value === defaultOptionValue))
      ) {
        setDefaultOptionSelected(true);
        const data = [{ value: 'all', label: 'All' }];
        return onChange(data);
      }

      setDefaultOptionSelected(false);
      return onChange(
        selectedOptions.filter(
          (selectedOption) => selectedOption?.value !== 'all',
        ),
      );
    } else {
      onChange(selectedOptions);
    }
  };

  const customStyles = {
    multiValue: (base, state) => {
      return {
        ...base,
        borderLeft:
          state?.data?.value !== defaultOptionValue &&
          getChipColors &&
          typeof getChipColors === 'function'
            ? `5px solid ${getChipColors(state?.data?.value)?.mauColor}`
            : 'inherit',
      };
    },
    multiValueRemove: (base, state) => ({
      ...base,
      opacity: state?.data?.value === defaultOptionValue ? 0 : 1,
      width: state?.data?.value === defaultOptionValue ? '2px' : 'auto',
    }),
  };

  return (
    <FormControl label={label} labelMinWidth={labelMinWidth} alignLeft>
      <Box width={maxWidth ? `${maxWidth}px` : '100%'}>
        <SelectControl
          isMulti={isMulti}
          menuPosition="absolute"
          placeholder={placeholder}
          onChange={handleToolsChange}
          options={options}
          value={selectedTools}
          styles={customStyles}
          isDisabled={disabled}
          {...props}
        />
      </Box>
    </FormControl>
  );
};

export default ToolsSelector;
