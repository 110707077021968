import React, { useMemo } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { BoxCard } from '../../index';

export const ReportToolsCards = ({
  title = 'Total',
  totalReportData,
  toolsNamesMap = {},
}) => {
  const totalCount = useMemo(
    () => Object.keys(toolsNamesMap).length ? totalReportData?.total?.count : totalReportData?.count,
    [toolsNamesMap]
  );

  return (
    <>
      <Grid2 container xs={12} mt={2} spacing={2} alignItems="stretch">
        <Grid2 xs={6} sm={4} md={3} lg={2}>
          <BoxCard
            useFlex
            title={title}
            value={totalCount || 0}
            borderColor="rgb(44,56,197)"
          />
        </Grid2>
      </Grid2>
    </>
  );
};
