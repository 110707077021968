import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const metricsApi = {
  getMetrics: ({ eventType = null, startDate = null, endDate = null, tool = null }) => {
    const params = {};

    if (eventType) {
      params.event_type = eventType;
    }

    if (startDate) {
      params.start_date = startDate;
    }

    if (endDate) {
      params.end_date = endDate;
    }

    if (tool) {
      params.tool = tool;
    }

    return getAxiosApiV2WithAuthorization({
      url: `/metrics`,
      params: params,
    });
  },
};

export default metricsApi;
