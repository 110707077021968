import React from 'react';
import styled from 'styled-components';

const StyledFormButtons = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;

  > * {
    margin-right: 16px;
  }

  &.align-right {
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;

    > * {
      margin-right: 0;
    }

    .right-group {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      gap: 1rem;
    }
  }
`;

const FormButtons = ({ children, leftContent, rightContent }) => {
  if (leftContent || rightContent) {
    return (
      <StyledFormButtons className="align-right">
        <div>
          { leftContent}
        </div>

        <div className="right-group">
          { rightContent }
        </div>
      </StyledFormButtons>
    );
  }

  return (
    <StyledFormButtons>
      {children}
    </StyledFormButtons>
  );
}

export default FormButtons;
