import axios from 'axios';

const awsService = {
  putFile: (presignedUrl, file, onUploadProgress) =>
    axios.request({
      method: 'put',
      url: presignedUrl,
      data: file,
      headers: {
        'Content-Type': file.type,
      },
      redirect: 'follow',
      onUploadProgress: onUploadProgress,
    }),
};

export default awsService;
