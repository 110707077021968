import { Card, CardContent, CircularLoader, Typography } from '@clatter/ui';
import { Box, Grid } from '@mui/material';
import LineChart from './LineChart';
import ChartLegend from './ChartLegend';
import styled from 'styled-components';

const StyledBaseCard = styled.div`
  > div {
    height: 100%;
    overflow: auto;
  }
`;

const StyledBaseCardHeader = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  min-height: 60px;
`;

const StyledCardRoot = styled.div`
  > div {
    padding: 0;
    height: 100%;
  }
`;

const StyledCardContent = styled.div`
  display: grid;
  min-height: ${({ minHeight }) => minHeight || '545px'};
  padding: 0 1em 0 1em;
`;

export const ChartCard = ({ title, loading, children, minHeight }) => {
  return (
    <StyledBaseCard>
      <Card>
        <StyledCardRoot>
          <CardContent>
            <StyledBaseCardHeader>
              <Typography variant="h4" component="span">
                {title}
              </Typography>
            </StyledBaseCardHeader>

            <StyledCardContent minHeight={minHeight}>
              {loading ? (
                <Grid item container justifyContent="center" xs={12}>
                  <Box pt={15}>
                    <CircularLoader />
                  </Box>
                </Grid>
              ) : (
                <>
                  { children }
                </>
              )}
            </StyledCardContent>
          </CardContent>
        </StyledCardRoot>
      </Card>
    </StyledBaseCard>
  );
};
