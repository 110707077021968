import { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { ChartCard } from './Components/ChartCard';
import { ChartLegend } from './Components/ChartLegend';
import LineChart from "./Components/LineChart";

export const ReportsChart = ({ reportData, loading, title }) => {
  // get chart legend
  const chartLegendData = useMemo(() => {
    if (!Array.isArray(reportData)) {
      return;
    }

    return (reportData || []).reduce((acc, item) => {
      let mappedData = {
        title: item?.title,
        data: [],
      };

      for (let datasetItem of item?.data.datasets) {
        mappedData.data.push({
          label: datasetItem?.label,
          value: datasetItem?.data[datasetItem?.data?.length - 1],
          color: datasetItem?.borderColor,
        });
      }

      acc.push(mappedData);

      return acc;
    }, []);
  }, [reportData]);

  // get chart data based on selected tools
  const getFilteredReportData = useMemo(() => {
    return {
      labels: reportData?.[0]?.data?.labels || [], // labels are the same for all datasets
      datasets: (reportData || []).map(reportDataRow => {
        return reportDataRow?.data?.datasets[0];
      }),
    };
  }, [reportData]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} md={12}>
        <ChartCard
          title={title}
          loading={loading}
          legendMedium
        >
          {!getFilteredReportData || !getFilteredReportData?.labels?.length ? (
            <Grid item container justifyContent="center" xs={12}>
              <Box pt={15}>
                <p>No data to display for selected date range.</p>
              </Box>
            </Grid>
          ) : (
            <>
              <LineChart reportData={getFilteredReportData} />
              <ChartLegend legendData={chartLegendData} />
            </>
          )}
        </ChartCard>
      </Grid>
    </Grid>
  );
};
