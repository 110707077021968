import { Alert, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const DEFAULT_API_UNAVAILABLE_ERROR_MESSAGE =
  'We’re sorry. We’re having trouble communicating with our servers at the moment. Please try again later.';

const ErrorBoundaryMessage = ({
  message = DEFAULT_API_UNAVAILABLE_ERROR_MESSAGE,
}) => {
  return (
    <StyledAlert variant="outlined" severity="error">
      <Typography variant="h6">{message}</Typography>
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
`;

export default ErrorBoundaryMessage;
