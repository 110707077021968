import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import usersApi from '../app/api/user.api';
import { requestStatus, sortAlphabetically } from '@clatter/platform';
import { groupBy } from "lodash";
export const USERS_FEATURE_KEY = 'users';
export const usersAdapter = createEntityAdapter({
  selectId: (row) => row.id,
  sortComparer: (a, b) => sortAlphabetically(a.name, b.name),
});

const mapUserApiToStore = (user) => ({
  ...user,
  id: user.id.toString(),
  _id: user.id,
  name: `${user.first_name?.trim()} ${user.last_name?.trim()}`,
});

const mapAiToStore = (users) => users.map(mapUserApiToStore);

export const fetchUsers = createAsyncThunk(
  `${USERS_FEATURE_KEY}/fetchStatus`,
  async () => await usersApi.fetchUsers(),
);

export const deleteUser = createAsyncThunk(
  `${USERS_FEATURE_KEY}/delete`,
  async ({ userId }) => await usersApi.deleteUser({ userId }),
);

export const updateUser = createAsyncThunk(
  `${USERS_FEATURE_KEY}/update`,
  async ({ userId, data }) => await usersApi.updateUser({ userId, data }),
);

export const initialUsersState = usersAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: undefined,
  totalRows: undefined,
});

export const usersSlice = createSlice({
  name: USERS_FEATURE_KEY,
  initialState: initialUsersState,
  reducers: {
    add: usersAdapter.addOne,
    remove: usersAdapter.removeOne,
    addUsers(state, { payload }){
        usersAdapter.addMany(state, mapAiToStore(payload) || []);
        state.totalRows = payload?.data?.total || undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        usersAdapter.setAll(state, mapAiToStore(payload?.data) || []);
        state.totalRows = payload?.data?.total || undefined;
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action.error.message;
      })
      // update user
      .addCase(updateUser.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(updateUser.fulfilled, (state, { meta }) => {
        // usersAdapter.updateOne(state, meta.arg.userId);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(updateUser.rejected, (state, { payload, error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      })
      // delete user
      .addCase(deleteUser.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(deleteUser.fulfilled, (state, { meta }) => {
        usersAdapter.removeOne(state, meta.arg.userId);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(deleteUser.rejected, (state, { payload, error }) => {
        state.loadingStatus = requestStatus.error;
        state.error = error.message;
      });
  },
});

export const usersReducer = usersSlice.reducer;

export const usersActions = usersSlice.actions;

const { selectAll, selectEntities } = usersAdapter.getSelectors();
export const getUsersState = (rootState) => rootState[USERS_FEATURE_KEY];
export const selectAllUsers = createSelector(getUsersState, selectAll);
export const selectTotalUsers = createSelector(
  getUsersState,
  (state) => state.totalRows,
);
export const selectUsersLoadingStatus = createSelector(
  getUsersState,
  (state) => state.loadingStatus,
);
export const selectUsersEntities = createSelector(
  getUsersState,
  selectEntities,
);

export const selectUsersByEmail = createSelector(getUsersState, state =>
  groupBy(selectAll(state), 'email')
);
