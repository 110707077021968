export const deckBuildStatuses = {
  completed: 'Completed',
  inProgress: 'In Progress',
  failed: 'Failed',
};

export const presentationBuildSteps = {
  init: 'initialization',
  templateProcessing: 'templateProcessing',
  assemblingSlides: 'assemblingSlides',
  toc: 'tableOfContents',
  finishing: 'finishingUp',
};
