import { NavLink } from 'react-router-dom';
import { sidebarNavLinkBaseStyles } from './sidebarNavLinkBaseStyles';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledNavLink = styled(NavLink)`
  ${sidebarNavLinkBaseStyles}
  &.indented {
    margin-left: 18px;
  }
`;

const StyledExternalLink = styled.a`
  ${sidebarNavLinkBaseStyles}
  cursor: pointer;

  &.indented {
    margin-left: 18px;
  }
`;

const SidebarNavLink = ({ item, isIndented, ...props }) => {
  const { label, url, icon: Icon, exact, external } = item;
  return (
    <>
      {external ? (
        <StyledExternalLink
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames({ indented: isIndented })}
          {...props}
        >
          {Icon && <Icon />}
          <span>{label}</span>
        </StyledExternalLink>
      ) : (
        <StyledNavLink
          end={exact}
          to={url}
          className={classNames({ indented: isIndented })}
          {...props}
        >
          {Icon && <Icon />}
          <span>{label}</span>
        </StyledNavLink>
      )}
    </>
  );
};

export default SidebarNavLink;
