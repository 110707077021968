import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { buttonVariants } from '../../config/consts';

const StyledButton = styled.button`
  cursor: pointer;
  background: white;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  width: 100%;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.buttonTextColor};
  border: 1px solid ${({ theme }) => theme.palette.grey};

  transition: all 0.3s ease-in-out;

  > span {
    flex-grow: 1;
  }

  &.has-menu-items {
    padding: 0 0 0 16px;
  }

  &.${buttonVariants.success} {
    background-color: ${({ theme }) => theme.palette.success};
    border-color: ${({ theme }) => theme.palette.success};
    color: ${({ theme }) => theme.palette.white};

    &:hover,
    &.opened {
      background-color: ${({ theme }) => theme.palette.white};
      color: ${({ theme }) => theme.palette.success};
    }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    border-color: ${({ theme }) => theme.palette.grey};
    color: ${({ theme }) => theme.palette.grey};
    background-color: #fff;
  }
`;

const StyledButtonIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  height: 100%;
  max-width: 50px;
  justify-self: flex-end;
  padding: 0 10px;
  border-left: 1px solid ${({ theme }) => theme.palette.darkGrey};

  &.${buttonVariants.success} {
    border-left-color: ${({ theme }) => theme.palette.white};

    &:hover,
    &.opened {
      border-left-color: ${({ theme }) => theme.palette.grey};
    }
  }
`;

const StyledMenuContainer = styled.div`
  position: relative;
`;

const StyledMenu = styled.div`
  position: absolute;
  display: none;
  background-color: white;
  border-radius: 5px;
  z-index: 2;
  min-width: 180px;
  top: 45px;
  right: 0;
  border: 1px solid ${({ theme }) => theme.palette.grey};
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);

  &.opened {
    display: block;
  }
`;

const StyledMenuItem = styled.div`
  cursor: pointer;
  padding: 10px 30px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.hoverBackgroundColor};
  }

  &.item-divider {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey};
  }
`;

const ButtonWithDropdown = ({
  label,
  disabled,
  variant,
  onClick,
  menuItems,
}) => {
  const [opened, setOpened] = useState(false);
  const dropDownRef = useRef(null);

  const hasMultipleMenuItems = useMemo(
    () => Array.isArray(menuItems) && menuItems.length > 1,
    [menuItems],
  );

  const handleButtonClick = () => {
    if (hasMultipleMenuItems) {
      return setOpened((prev) => !prev);
    }
    onClick(menuItems[0].value);
  };

  const handleClickOutside = (event) => {
    if (
      opened &&
      dropDownRef &&
      !dropDownRef?.current?.contains(event?.target)
    ) {
      setOpened(false);
    }
  };

  const handleMenuItemClick = (value) => {
    onClick(value);
    setOpened(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened]);

  return (
    <>
      <StyledMenuContainer ref={dropDownRef}>
        <StyledButton
          onClick={handleButtonClick}
          className={classNames(variant, {
            'has-menu-items': hasMultipleMenuItems,
            opened: opened,
          })}
          disabled={disabled}
          data-test-id="content-slide-download-button"
        >
          <span>{label || 'Download'}</span>

          {hasMultipleMenuItems && (
            <StyledButtonIcon
              className={classNames(variant, { opened: opened })}
            >
              {opened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </StyledButtonIcon>
          )}
        </StyledButton>

        {hasMultipleMenuItems && (
          <StyledMenu className={classNames({ opened: opened })}>
            {menuItems.map(({ label, value, testId }, index) => (
              <StyledMenuItem
                key={value}
                className={classNames({
                  'item-divider': index !== menuItems.length - 1,
                  disabled: disabled,
                })}
                onClick={() => handleMenuItemClick(value)}
                data-test-id={testId}
              >
                {label}
              </StyledMenuItem>
            ))}
          </StyledMenu>
        )}
      </StyledMenuContainer>
    </>
  );
};

export default ButtonWithDropdown;
