import React, { Suspense, lazy, useEffect } from 'react';
import { matchPath, Route, Routes, useLocation } from 'react-router-dom';
import {
  fetchToolInfo,
  fetchToolsConfig,
  fetchFeatureFlag,
  useAuth,
  fetchRoles,
  fetchContentRepositories,
  userRolesMap,
  fetchSystemProfile,
  sharedRoutes,
  fetchCustomAttributes,
} from '@clatter/platform';
import { createGlobalStyle } from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  Loader,
  Analytics,
  ProtectedRoute,
  LogoutPageView,
  SsoCallbackPage,
  AppThemeProvider,
  TrackPageviewEvent,
  appGlobalStyles,
} from '@clatter/ui';
import routes from './routes';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

const LandingPageView = lazy(() =>
  import('./pages/LandingPage/LandingPageView'),
);
const DashboardView = lazy(() => import('./pages/Dashboard/DashboardView'));
const UsersListView = lazy(() => import('./pages/Users/UsersListView'));
const UserFormView = lazy(() => import('./pages/Users/UserFormView'));
const UserCustomAttributesView = lazy(() =>
  import('./pages/Users/UserCustomAttributesView'),
);
const UsersLoginsReportView = lazy(() =>
  import('./pages/Reports/UsersLogins/UsersLoginsReportView'),
);
const UsersLoginsDetailsReportView = lazy(() =>
  import('./pages/Reports/UsersLogins/UsersLoginsDetailsReportView'),
);
const FeatureFlagView = lazy(() =>
  import('./pages/FeatureFlag/FeatureFlagView'),
);
const SystemConfigurationView = lazy(() =>
  import('./pages/SystemConfiguration/SystemConfigurationView'),
);
const ActiveUsersReportView = lazy(() =>
  import('./pages/Reports/ActiveUsers/ActiveUsersReportView'),
);
const FailedLoginsReportView = lazy(() =>
  import('./pages/Reports/FailedLogins/FailedLoginsReportView'),
);
const FailedLoginsDetailsReportView = lazy(() =>
  import('./pages/Reports/FailedLogins/FailedLoginsDetailsReportView'),
);
const PageViewsReportView = lazy(() =>
  import('./pages/Reports/PageViews/PageViewsReportView'),
);
const UnauthorizedUserAccessAttemptsReportView = lazy(() =>
  import('./pages/Reports/UnauthorizedUserAccessAttempts/UnauthorizedUserAccessAttemptsReportView'),
);
const UnauthorizedUserAccessAttemptsDetailsReportView = lazy(() =>
  import('./pages/Reports/UnauthorizedUserAccessAttempts/UnauthorizedUserAccessAttemptsDetailsReportView'),
);
const SlidesDownloadsReportView = lazy(() =>
  import('./pages/Reports/SlidesDownloads/SlidesDownloadsReportView'),
);
const AnalyticsView = lazy(() =>
  import('./pages/Reports/Analytics/AnalyticsView'),
);
const AssetUsageToolListView = lazy(() =>
  import('./pages/Reports/AssetUsage/AssetUsageToolListView'),
);
const AssetUsageToolDetailsView = lazy(() =>
  import('./pages/Reports/AssetUsage/AssetUsageToolDetailsView'),
);
const AssetUsageAssetListView = lazy(() =>
  import('./pages/Reports/AssetUsage/AssetUsageAssetListView'),
);
const AssetUsageAssetDetailsView = lazy(() =>
  import('./pages/Reports/AssetUsage/AssetUsageAssetDetailsView'),
);
const UtilizationDashboard = lazy(() =>
  import('./pages/Reports/UtilizationDashboard/UtilizationDashboard'),
);

export function App() {
  const GlobalStyle = createGlobalStyle`
    ${appGlobalStyles};
  `;

  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();
  const location = useLocation();

  const isOnLogoutPage = !!matchPath(routes.logout, location?.pathname);
  const isOnSsoCallbackPage = !!matchPath(sharedRoutes.ssoCallback, location?.pathname);

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchRoles());
      dispatch(fetchSystemProfile());
      dispatch(fetchContentRepositories());
      dispatch(fetchCustomAttributes());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <TrackPageviewEvent />
      <Analytics />

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={routes.index} element={<LandingPageView />} />
          <Route path={routes.logout} element={<LogoutPageView />} />
          <Route path={sharedRoutes.ssoCallback} element={<SsoCallbackPage />} />

          <Route
            path={routes.userCustomAttributes}
            element={<ProtectedRoute element={UserCustomAttributesView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.users}
            element={<ProtectedRoute element={UsersListView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.addUser}
            element={<ProtectedRoute element={UserFormView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.editUser}
            element={<ProtectedRoute element={UserFormView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.systemConfiguration}
            element={<ProtectedRoute element={SystemConfigurationView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.featureFlags}
            element={<ProtectedRoute element={FeatureFlagView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.dashboard}
            element={<ProtectedRoute element={DashboardView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.analytics}
            element={<ProtectedRoute element={AnalyticsView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportUtilizationDashboard}
            element={<ProtectedRoute element={UtilizationDashboard} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportActiveUsers}
            element={<ProtectedRoute element={ActiveUsersReportView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportFailedLogins}
            element={<ProtectedRoute element={FailedLoginsReportView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportFailedLoginsDetails}
            element={
              <ProtectedRoute element={FailedLoginsDetailsReportView} accessRoles={[userRolesMap.clatterAdmin]} />
            }
          />

          <Route
            path={routes.reportPageViews}
            element={<ProtectedRoute element={PageViewsReportView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportUnauthorizedUserAccessAttempts}
            element={
              <ProtectedRoute
                element={UnauthorizedUserAccessAttemptsReportView}
                accessRoles={[userRolesMap.clatterAdmin]}
              />
            }
          />

          <Route
            path={routes.reportUnauthorizedUserAccessAttemptsDetails}
            element={
              <ProtectedRoute
                element={UnauthorizedUserAccessAttemptsDetailsReportView}
                accessRoles={[userRolesMap.clatterAdmin]}
              />
            }
          />

          <Route
            path={routes.reportSlidesDownloads}
            element={<ProtectedRoute element={SlidesDownloadsReportView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportAssetUsage}
            element={<ProtectedRoute element={AssetUsageToolListView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportUnusedAsset}
            element={<ProtectedRoute element={AssetUsageToolListView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportAssetUsageToolDetails}
            element={<ProtectedRoute element={AssetUsageToolDetailsView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportUnusedAssetToolDetails}
            element={<ProtectedRoute element={AssetUsageToolDetailsView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportAssetUsageToolAssetDetails}
            element={<ProtectedRoute element={AssetUsageAssetListView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportUnusedAssetToolAssetDetails}
            element={<ProtectedRoute element={AssetUsageAssetListView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportAssetUsageAssetDetails}
            element={<ProtectedRoute element={AssetUsageAssetDetailsView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportUnusedAssetAssetDetails}
            element={<ProtectedRoute element={AssetUsageAssetDetailsView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />

          <Route
            path={routes.reportUsersLoginsDetails}
            element={
              <ProtectedRoute element={UsersLoginsDetailsReportView} accessRoles={[userRolesMap.clatterAdmin]} />
            }
          />

          <Route
            path={routes.reports}
            element={<ProtectedRoute element={UsersLoginsReportView} accessRoles={[userRolesMap.clatterAdmin]} />}
          />
        </Routes>
      </Suspense>
    </AppThemeProvider>
  );
}

export default App;
