import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getMaskedValue } from '@clatter/platform';

const StyledCustomerBio = styled.button`
  display: flex;
  align-self: baseline;
  outline: none;
  border: none;
  background-color: transparent;
  max-width: ${({ theme }) => theme.customerBio?.maxWidth || '420px'};
  padding: ${({ theme }) => theme.customerBio?.padding || 0};

  .team-bio-image {
    border-radius: 50%;
    width: ${({ theme }) => theme.customerBio?.avatar.size || '85px'};
    height: ${({ theme }) => theme.customerBio?.avatar.size || '85px'};
    background-size: cover;
    background-position: center center;
    background-image: ${({ image }) => (image ? `url(${image})` : 'none')};

    flex-shrink: 0;

    &.no-image {
      background-color: #f4f4f4;
      border: 1px solid #ddd;

      span {
        margin-top: 50%;
        display: block;
        transform: translateY(-50%);
      }
    }
  }

  .team-bio-details {
    text-align: ${({ theme }) => theme.customerBio?.details?.textAlign || 'left'};
    padding: ${({ theme }) => theme.customerBio?.details?.padding || '5px 0 0 26px'};
    font-family: ${({ theme }) => theme.body.fontFamily || 'Arial, sans-serif'};

    &.no-padding-top {
      padding-top: 0;
    }

    a {
      cursor: pointer;

      :hover {
        color: ${({ theme }) => theme?.link?.color || '#454b49'};
        text-decoration: underline;
      }
    }

    .team-bio-name {
      font-family: ${({ theme }) => theme.customerBio?.details?.name.fontFamily || 'Arial, sans-serif'};
      font-size: ${({ theme }) => theme.customerBio?.details?.name.fontSize || '20px'};
      font-weight: ${({ theme }) => theme.customerBio?.details?.name.fontWeight || 700};
      color: ${({ theme }) => theme.customerBio?.details?.name.color || '#002677'};
      margin: ${({ theme }) => theme.customerBio?.details?.name.margin || 0};
    }

    .team-bio-title {
      font-family: ${({ theme }) => theme.customerBio?.details?.title.fontFamily || 'Arial, sans-serif'};
      font-size: ${({ theme }) => theme.customerBio?.details?.title.fontSize || '16px'};
      font-weight: ${({ theme }) => theme.customerBio?.details?.title.fontWeight || 400};
      color: ${({ theme }) => theme.customerBio?.details?.title.color || '#404040'};
      line-height: ${({ theme }) => theme.customerBio?.details?.title.lineHeight || 1.7};
    }

    .team-bio-email {
      display: block;
      font-family: ${({ theme }) => theme.customerBio?.details?.email.fontFamily || 'inherit'};
      font-size: ${({ theme }) => theme.customerBio?.details?.email.fontSize || 'inherit'};
      font-weight: ${({ theme }) => theme.customerBio?.details?.email.fontWeight || 'inherit'};
      color: ${({ theme }) => theme.customerBio?.details?.email.color || 'inherit'};
      margin: ${({ theme }) => theme.customerBio?.details?.email.margin || '16px 0'};
    }

    .team-bio-phone {
      display: block;
      font-family: ${({ theme }) => theme.customerBio?.details?.phone.fontFamily || 'inherit'};
      font-size: ${({ theme }) => theme.customerBio?.details?.phone.fontSize || 'inherit'};
      font-weight: ${({ theme }) => theme.customerBio?.details?.phone.fontWeight || 'inherit'};
      color: ${({ theme }) => theme.customerBio?.details?.phone.color || 'inherit'};
      margin: ${({ theme }) => theme.customerBio?.details?.phone.margin || '16px 0'};
    }

    .team-bio-description {
      display: block;
      font-family: ${({ theme }) => theme.customerBio?.details?.description.fontFamily || 'inherit'};
      font-size: ${({ theme }) => theme.customerBio?.details?.description.fontSize || 'inherit'};
      font-weight: ${({ theme }) => theme.customerBio?.details?.description.fontWeight || 'inherit'};
      color: ${({ theme }) => theme.customerBio?.details?.description.color || 'inherit'};
      margin: ${({ theme }) => theme.customerBio?.details?.description.margin || '16px 0 0'};
      line-height: ${({ theme }) => theme.customerBio?.details?.description.lineHeight || 1.4};
    }

    .team-bio-click {
      font-family: ${({ theme }) => theme.customerBio?.details?.bioClick.fontFamily || 'Arial, sans-serif'};
      margin: ${({ theme }) => theme.customerBio?.details?.bioClick.margin || '4px 0'};
      color: ${({ theme }) => theme.customerBio?.details?.bioClick.color || '#196ECF'};
      text-decoration: ${({ theme }) => theme.customerBio?.details?.bioClick.textDecoration || 'none'};
      font-size: ${({ theme }) => theme.customerBio?.details?.bioClick.fontSize || '1.05em'};
      font-weight: ${({ theme }) => theme.customerBio?.details?.bioClick.fontWeight || 'inherit'};

      &:hover {
        text-decoration: ${({ theme }) => theme.customerBio?.details?.bioClick.hoverTextDecoration || 'underline'};
      }
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.footer-item {
    .team-bio-email,
    .team-bio-phone {
      margin: 0.3rem auto;
    }
  }
`;

export const CustomerBio = ({
  description,
  email,
  name,
  image,
  onClickText,
  phone,
  title,
  link,
  onClick,
  footerItem = false,
  ...dataAttributes
}) => (
  <StyledCustomerBio
    {...dataAttributes}
    type="button"
    image={image}
    onClick={onClick}
    className={classNames('customer-bio', {
      clickable: typeof onClick === 'function',
      'footer-item': footerItem,
    })}
    data-clatter-event={JSON.stringify({
      type: 'Bio Click',
      data: {
        name: dataAttributes['data-bio-name'],
      },
    })}
  >
    <div className={classNames('team-bio-image', { 'no-image': !image })}>{!image && <span>No image</span>}</div>
    <div
      className={classNames('team-bio-details', {
        'no-padding-top': footerItem && (email || phone),
      })}
    >
      {link ? (
        <Link to={link} className="team-bio-name">
          {name}
        </Link>
      ) : (
        <div className="team-bio-name">{name}</div>
      )}
      <div className="team-bio-title">{title}</div>
      {!!onClickText && <div className="team-bio-click">{onClickText}</div>}
      {!!email && (
        <a className="team-bio-email" href={`mailto: ${email}`}>
          {email}
        </a>
      )}
      {!!phone && <p className="team-bio-phone">{getMaskedValue('xxx-xxx-xxxx', phone)}</p>}
    </div>
  </StyledCustomerBio>
);
