import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import styled from 'styled-components';
import { useDeepCompareMemo, useGetActiveTool } from "@clatter/platform"; // FIXME: circular deps !!!

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const StyledDisabledLink = styled(Typography)`
  cursor: default;
  text-transform: capitalize;
`

const Breadcrumbs = ({ links, separator, pageTitle }) => {
  const activeTool = useGetActiveTool();
  const navigate = useNavigate();

  const linksWithAppName = useDeepCompareMemo(() => {
    const updated = [
      {
        underline: 'hover',
        color: 'inherit',
        url: '/',
        label: activeTool?.name,
      },
      ...(links || []),
    ]

    if (!links?.[links?.length - 1]?.isCurrent) {
      updated.push(
        {
          underline: 'hover',
          color: 'inherit',
          url: '',
          label: pageTitle,
        })
    }

    return updated;
  }, [links, activeTool, pageTitle])

  const handleLinkClick = (url) => navigate(url);

  return (
    <MuiBreadcrumbs separator={separator ?? '/'} aria-label="breadcrumb" mb={1}>
      {linksWithAppName.map((link, index) => {
        if (index === linksWithAppName.length - 1 || link?.disabled) {
          return (
            <StyledDisabledLink
              key={`${link.label}-${index}`}
              title={link.label}
              color={'#ababab'}
            >
              {link.label}
            </StyledDisabledLink>
          );
        }
        return (
          <StyledLink
            title={link.label}
            key={`${link.label}-${index}`}
            underline={link.underline}
            color="text.primary"
            onClick={() => link?.onClick ? link.onClick() : handleLinkClick(link.url)}
          >
            {link.label}
          </StyledLink>
        );
      })}
    </MuiBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      underline: PropTypes.string,
      color: PropTypes.string,
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

export default Breadcrumbs;
