import React, { useState } from 'react';
import {
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import {
  buttonWidths,
  DataGridPremium,
  IconButton,
  Tabs,
  TabsContent,
} from '@clatter/ui';
import { handleExportAsCSV } from '@clatter/platform';

const CustomToolbar = ({ exportFileName }) => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer
      style={{
        justifyContent: 'end',
      }}
    >
      <IconButton
        tooltip="Export"
        width={buttonWidths.md}
        onClick={() =>
          handleExportAsCSV({
            apiRef: apiRef,
            exportFileName: exportFileName,
          })
        }
      >
        <CloudDownloadIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};

export const ReportDetailsSection = ({
  loading = false,
  rows = [],
  columns = [],
  exportFileName,
  tableName,
  tabsData = null,
  minHeight,
  getRowId = (row) => row.id,
  defaultSortField,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  if (!tabsData) {
    return (
      <DataGridPremium
        loading={loading}
        dataGridProps={{
          components: {
            Toolbar: () =>
              CustomToolbar({
                exportFileName,
              }),
          },
        }}
        getRowId={getRowId}
        name={tableName} //  "report/active-users"
        columns={columns}
        rows={rows}
        exportFileName={exportFileName}
        defaultSortField={defaultSortField}
      />
    );
  }

  return (
    <>
      <Tabs
        tabs={tabsData?.tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      >
        {tabsData?.tabsData?.map((tableData, index) => {
          return (
            <TabsContent key={`${index}-details-report-tab`} minHeight={minHeight}>
              <DataGridPremium
                loading={loading}
                dataGridProps={{
                  components: {
                    Toolbar: () =>
                      CustomToolbar({
                        exportFileName: tableData?.exportFileName,
                      }),
                  },
                }}
                getRowId={(row) => row.id}
                name={tableData?.tableName} //  "report/active-users"
                columns={tableData?.columns}
                rows={tableData?.rows}
                exportFileName={tableData?.exportFileName}
                defaultSortField={{ field: 'count', sort: 'desc' }}
              />
            </TabsContent>
          );
        })}
      </Tabs>
    </>
  );
};
