import axios from 'axios';

export const revalidatePages = async ({ currentMicrosite, revalidateUrl }) => {
  if (!currentMicrosite?.attributes?.pages || !currentMicrosite?.attributes?.pages?.data?.length) {
    return false;
  }

  await axios.post(revalidateUrl, {
    secret: 'MY_SECRET_TOKEN',
    paths: currentMicrosite?.attributes?.pages?.data?.map(
      (page) => `/sites/${currentMicrosite?.attributes?.name}/${page.attributes.title}`,
    ),
  });
};
