import React from 'react';
import IconButton from '../IconButton/IconButton';
import {
  MailOutline as MailOutlineIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@mui/icons-material';

const renderIcon = (type) => {
  switch (type) {
    case 'email':
      return <MailOutlineIcon />;
    case 'edit':
      return <EditOutlinedIcon />;
    case 'delete':
      return <DeleteOutlinedIcon />;
    default:
      break;
  }
};

const DataGridBulkEditButton = ({ onClick, type, tooltip, ...props }) => {
  return (
    <IconButton
      onClick={onClick}
      tooltip={tooltip ?? 'Edit selected'}
      {...props}
    >
      {renderIcon(type ?? 'edit')}
    </IconButton>
  );
};

export default DataGridBulkEditButton;
