import React from 'react';
import styled from 'styled-components';

const StyledStepCardContent = styled.div`
  padding: 24px 48px 24px 136px;
`;

const StepCardContent = ({ children }) => (
  <StyledStepCardContent>{children}</StyledStepCardContent>
);

export default StepCardContent;
