import { Link } from '@clatter/ui';
import { formatDateTime } from '@clatter/platform';
import React from 'react';

const getTableColumnByFieldName = (field, assetSlug) => {
  switch (field) {
    case 'name':
      if (assetSlug === 'topics') {
        return {
          headerName: 'File Name',
          field: 'name',
          sortable: true,
          flex: 0.5,
        };
      }
      return {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        renderCell: ({ row }) => (row?.pathname ? <Link to={row.pathname}>{row.name}</Link> : row.name),
        flex: 1,
      };
    case 'displayName':
      return {
        headerName: 'Name',
        field: 'displayName',
        sortable: true,
        renderCell: ({ row }) => (row?.pathname ? <Link to={row.pathname}>{row.displayName}</Link> : row.displayName),
        flex: 1,
      };
    case 'company':
      return {
        headerName: 'Company',
        field: 'company',
        sortable: true,
        renderCell: ({ row }) => (row?.pathname ? <Link to={row.pathname}>{row.company}</Link> : row.company),
        flex: 1,
      };
    case 'title':
      return {
        headerName: 'Title',
        field: 'title',
        sortable: true,
        renderCell: ({ row }) => (row?.pathname ? <Link to={row.pathname}>{row.title}</Link> : row.title),
        flex: 1,
      };
    case 'filename':
      return {
        headerName: 'File Name',
        field: 'filename',
        sortable: true,
        renderCell: ({ row }) => row?.filename || 'N/A',
        flex: 1,
      };
    case 'asset_file_name':
      return {
        headerName: 'File Name',
        field: 'asset_file_name',
        sortable: true,
        renderCell: ({ row }) => row?.asset_file_name || 'N/A',
        flex: 1,
      };
    case 'category':
      return {
        headerName: 'Category',
        field: 'category',
        sortable: true,
        renderCell: ({ row }) => row?.category || 'N/A',
        flex: 1,
      };
    case 'theme':
      return {
        headerName: 'Theme',
        field: 'theme',
        sortable: true,
        valueGetter: ({ row }) => row?.theme?.title || 'N/A',
        flex: 0.5,
      };
    case 'description':
      return {
        headerName: 'Description',
        field: 'description',
        sortable: true,
        type: 'text',
        renderCell: ({ row }) =>
          assetSlug === 'hero_images' && row?.pathname ? (
            <Link to={row.pathname}>{row.description}</Link>
          ) : (
            row.description
          ),
        flex: assetSlug === 'hero_images' ? 1 : 0.5,
      };
    case 'email':
      return {
        headerName: 'Email',
        field: 'email',
        renderCell: ({ row }) => (
          <a href={`mailto:${row.email}`} style={{ textDecoration: 'underline' }}>
            {row.email}
          </a>
        ),
        sortable: true,
        flex: 0.5,
      };
    case 'phone':
      return {
        headerName: 'Phone',
        field: 'phone',
        renderCell: ({ row }) => (
          <a href={`tel:${row.phone}`} style={{ textDecoration: 'underline' }}>
            {row.phone}
          </a>
        ),
        sortable: true,
        flex: 0.5,
      };
    case 'contentRepository':
      return {
        headerName: 'Repository Name',
        field: 'contentRepository',
        sortable: true,
        valueGetter: ({ row }) => row?.contentRepository?.title || 'N/A',
        flex: 1,
      };
    case 'contentSlideCategory':
      return {
        headerName: 'Category Name',
        field: 'contentSlideCategory',
        sortable: true,
        valueGetter: ({ row }) => row?.contentSlideCategory?.title || 'N/A',
        flex: 1,
      };
    case 'usage_count':
      return {
        headerName: 'Usage Count',
        field: 'usage_count',
        type: 'number',
        sortable: true,
        flex: 0.3,
      };
    case 'createdBy':
      return {
        headerName: 'Created By',
        field: 'createdBy',
        sortable: true,
        flex: 0.5,
      };
    case 'updatedBy':
      return {
        headerName: 'Updated By',
        field: 'updatedBy',
        sortable: true,
        flex: 0.5,
      };
    case 'createdAt':
      return {
        headerName: 'Created',
        field: 'createdAt',
        type: 'date',
        renderCell: ({ value }) => (value ? formatDateTime(value) : 'N/A'),
        sortable: true,
        flex: 0.3,
      };
    case 'updatedAt':
      return {
        headerName: 'Last Modified',
        field: 'updatedAt',
        type: 'date',
        renderCell: ({ value }) => (value ? formatDateTime(value) : 'N/A'),
        sortable: true,
        flex: 0.3,
      };
    default:
      break;
  }
};

export const getAssetTypeTableColumns = ({ assetStruct, assetSlug }) => {
  // @fixme - this should get into account also field type...
  const columns = Object.keys(assetStruct)
    .map((structKey) => getTableColumnByFieldName(structKey, assetSlug))
    .filter((item) => item);

  const hasDisplayName = assetStruct.hasOwnProperty('displayName');

  return columns?.length
    ? columns
    : [
        {
          headerName: hasDisplayName ? 'Name' : 'Title',
          field: hasDisplayName ? 'displayName' : 'title',
          sortable: true,
          renderCell: ({ row }) => (row?.pathname ? <Link to={row.pathname}>{row.displayName}</Link> : row.displayName),
          flex: 1,
        },
      ];
};
