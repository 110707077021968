import styled from 'styled-components';

const StyledChartLegendContainer = styled.ul`
  margin: 0;
  text-align: center;
  padding: 2em 0;
`;

const StyledPeriodItem = styled.li`
  padding-right: 15px;
  display: inline-block;

  .period-label {
    margin: 0;
    color: #717579;
    font-size: 1rem;
    font-weight: 700;
    line-height: 35px;

    &:before {
      top: 3px;
      content: '•';
      font-size: 25px;
      margin-right: 5px;
      position: relative;
      color: ${({color}) => color || 'inherit'};
    }
  }
`;

const PeriodItem = ({ label, color }) => (
  <StyledPeriodItem color={color}>
    <span className="period-label">{label}</span>
  </StyledPeriodItem>
);

const ChartLegendItem = ({ item }) => (
  <>
    {(item?.data || []).map((periodItem) => (
      <PeriodItem
        key={periodItem?.label}
        color={periodItem?.color}
        label={item?.title}
      />
    ))}
  </>
);

const renderLegends = (legendData) => {
  if (!legendData) {
    return null;
  }

  return (legendData || []).map((legendItem) => (
    <ChartLegendItem key={legendItem?.title} item={legendItem} />
  ));
};

export const ChartLegend = ({ legendData }) => (
  <StyledChartLegendContainer>
    {renderLegends(legendData)}
  </StyledChartLegendContainer>
);
