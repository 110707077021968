import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '@clatter/ui';
import { isAuthCookieAvailable, useAuth, isLocalhost } from '@clatter/platform';

const withRoleBasedRedirect = (Component, options) => (props) => {
  const { user } = useAuth();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const roles = user?.[`${process.env.NX_AUTH0_NAMESPACE}/roles`] || [];
    const isAuthorized = options.accessRoles.some((role) => roles.includes(role));

    if (isAuthorized) {
      setIsAuthorized(true);
    } else {
      console.info(
        `Access denied. User roles: [${roles.join(', ')}]. Access roles: [${options.accessRoles.join(', ')}]`
      );

      window.location.replace(
        isLocalhost()
          ? 'http://localhost:3000'
          : process.env.NX_APP_ROOT_DOMAIN
      );
    }
  }, [user, options.accessRoles]);

  return isAuthorized ? <Component {...props} /> : <Loader />;
};

// ProtectedRoute component
const ProtectedRoute = ({ element: Component, accessRoles }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthCookieAvailable() || (!isAuthenticated && !isLoading)) {
      navigate('/logout', { state: { redirectTo: window.location.origin + location.pathname + location.search } });
    }
  }, [isAuthenticated, isLoading, navigate, location.pathname, location.search]);

  // Show a loader while checking authentication
  if (isLoading) {
    return <Loader />;
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // If roles are specified, wrap the component with role-based checks
  const AuthWrappedComponent = accessRoles
    ? withRoleBasedRedirect(Component, { accessRoles })
    : Component;

  // Render the authenticated and authorized component as JSX
  return <AuthWrappedComponent />;
};

export default ProtectedRoute;
