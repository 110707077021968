import React, { useMemo } from 'react';
import { Link, Stack } from '@mui/material';
import { Label, ReportDetailsSection, Typography } from '@clatter/ui';
import { useDeepCompareMemo } from '@clatter/platform';
import styled from 'styled-components';
import { CustomerBio } from './components/previewComponents/CustomerBio';
import { VideoGalleryItem } from './components/previewComponents/VideoGalleryItem';
import { ResourceItem } from './components/previewComponents/ResourceItem';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

const StyledImage = styled.img`
  max-height: 450px;
  object-fit: contain;
`;

const StyledLink = styled.div`
  a {
    display: flex;
    gap: 10px;
    align-items: center;
    color: ${({ theme }) => theme.palette.linkColor || 'black'};
    text-decoration-color: ${({ theme }) => theme.palette.linkColor || 'black'};
  }
`;

const renderBiosDetails = (item) => (
  <Stack spacing={4} direction="column">
    <CustomerBio
      data-id={item.id}
      data-item-name={`${item.displayName}, ${item.title}`}
      key={item.displayName}
      image={item.profilePicture}
      name={item.displayName}
      email={item?.email}
      phone={item?.phone}
      title={item.title}
    />

    <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
  </Stack>
);

const renderVideosDetails = (item) => <VideoGalleryItem key={item._id} video={item} />;

const renderResourcesDetails = (item) => <ResourceItem resource={item} />;

const renderGenericDetails = (item, showDescription = true) => (
  <>
    <Stack direction="column" spacing={4}>
      {!item?.src && !item?.asset && !item?.url ? (
        <Stack mt={5} justifyContent="center" alignItems="center">
          <Typography variant="body2">Image not found</Typography>
        </Stack>
      ) : (
        <StyledImage src={item?.src || item?.asset || item?.url} alt={item?.title || 'N/A'} />
      )}

      {showDescription && item?.description && (
        <>
          <Label>Description:</Label>
          <Typography variant="body2">{item?.description}</Typography>
        </>
      )}
    </Stack>
  </>
);

export const AssetUsageAssetDetails = ({ assetData, assetType, toolSlug, isLoading, exportFileName, tableName }) => {
  const baseWhereUsedUrl = useMemo(() => {
    switch (toolSlug) {
      case 'msm':
        const baseUrl = process.env.NX_SITES_HOST;
        if (!baseUrl) {
          throw new Error('Error: env variable NX_SITES_HOST is not set');
        }
        return baseUrl;
      default:
        break;
    }
  }, [toolSlug]);

  const whereUsedColumns = useDeepCompareMemo(() => {
    switch (toolSlug) {
      case 'cm':
        return [
          {
            headerName: 'Document',
            field: 'name',
            sortable: true,
            flex: 1,
            renderCell: ({ value, row }) => (
              <StyledLink>
                <Link href={row.url} target="_blank" rel="noopener noreferrer">
                  {value}
                  <OpenInNewIcon style={{ fontSize: '1rem' }} />
                </Link>
              </StyledLink>
            ),
          },
        ];
      case 'msm':
        const msmColumns = [
          {
            headerName: 'Microsite',
            field: 'microsite',
            sortable: true,
            flex: 1,
          },
        ];

        if (assetType !== 'cobrand_logos') {
          msmColumns.push({
            headerName: 'Page',
            field: 'page',
            sortable: true,
            renderCell: ({ value, row }) =>
              row?.url ? (
                <StyledLink>
                  <Link href={row.url} target="_blank" rel="noopener noreferrer">
                    {value}
                    <OpenInNewIcon style={{ fontSize: '1rem' }} />
                  </Link>
                </StyledLink>
              ) : (
                value
              ),
            flex: 1,
          });
        }

        msmColumns.push({
          headerName: 'Owned By',
          field: 'owned_by',
          sortable: true,
          flex: 1,
        },)

        return msmColumns;
      case 'pm-sl':
        return [
          {
            headerName: 'Presentation',
            field: 'presentationName',
            sortable: true,
            flex: 1,
          },
        ];
    }
  }, [toolSlug]);

  const whereUsedRows = useDeepCompareMemo(() => {
    switch (toolSlug) {
      case 'msm':
        return (assetData?.where_used || []).map((item, index) => ({
          _id: item?._id || index,
          microsite: item?.microsite,
          page: item?.page,
          owned_by: item?.microsite_owner,
          url: `${baseWhereUsedUrl}/sites/${item?.microsite}/${item?.page}`,
        }));
      case 'cm':
        return (assetData?.where_used || []).map((item) => ({
          ...item,
          url: `/${item?._id}`,
        }));
      default:
        return assetData?.where_used;
    }
  }, [assetData, baseWhereUsedUrl, toolSlug]);

  const renderAssetDetails = ({ assetData, assetType }) => {
    if (!assetData) {
      return <></>;
    }
    switch (assetType) {
      case 'bios':
        return renderBiosDetails(assetData);
      case 'videos':
        return renderVideosDetails(assetData);
      case 'hero_images':
        return renderGenericDetails(assetData, false);
      case 'resources':
        return renderResourcesDetails(assetData);
      default:
        return renderGenericDetails(assetData);
    }
  };

  return (
    <Stack direction="column" spacing={4} mt={5}>
      {renderAssetDetails({ assetData, assetType })}
      {whereUsedRows?.length > 0 && (
        <>
          <Stack direction="column" mb={4} spacing={0}>
            <Typography variant="h4" component="h3">
              Where used:
            </Typography>
            <ReportDetailsSection
              getRowId={(row) => row._id}
              loading={isLoading}
              tableName={tableName}
              exportFileName={exportFileName}
              rows={whereUsedRows}
              columns={whereUsedColumns}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
