import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useDeepCompareMemo } from '@clatter/platform';
import Link from '../../../Link/link';
import styled from 'styled-components';
import { VideoGalleryItem } from './previewComponents/VideoGalleryItem';
import { CustomerBio } from './previewComponents/CustomerBio';
import { AssetUsageThumbnail } from './previewComponents/AssetUsageThumbnail';
import Grid2 from '@mui/material/Unstable_Grid2';

const StyledTitle = styled.span`
  font-size: 1.1rem;
`;

const StyledHoverableItem = styled.div`
  height: 100%;

  &:hover {
    > :first-child {
      transform: scale(0.93);
      transition: all 0.3s ease-in-out;
    }

    transition: all 0.3s ease-in-out;
    box-shadow: ${({ theme }) => theme?.slideSortThumbnail?.hoverShadow || 'rgb(0 0 0 / 14%) 4px 8px 20px 0px'};
  }
`;

const renderAssetPreview = ({ zoomValue, item, index, assetType }) => {
  switch (assetType) {
    case 'bios':
      return (
        <Stack direction="column"
               spacing={1}
               justifyContent="space-between"
               style={{ height: '100%' }}>
          <StyledHoverableItem>
            <CustomerBio
              data-id={item?.id}
              data-item-name={`${item?.displayName}, ${item?.title}`}
              link={item?.pathname}
              image={item?.profilePicture}
              name={item?.displayName}
              email={item?.email}
              phone={item?.phone}
              title={item?.title}
            />
          </StyledHoverableItem>
          <div>Usage Count: {item?.usage_count}</div>
        </Stack>
      );
    case 'videos':
      return (
        <Stack
          direction="column"
          spacing={1}
          justifyContent="space-between"
          style={{ height: '100%' }}
        >
          <StyledHoverableItem>
            <VideoGalleryItem video={item} showDescription={false} />
          </StyledHoverableItem>
          <div>Usage Count: {item?.usage_count}</div>
        </Stack>
      );
    default:
      return (
        <AssetUsageThumbnail
          fullWidth
          zoomValue={zoomValue}
          title={
            <Link to={item?.pathname}>
              <StyledTitle style={{ marginBottom: '5px' }}>
                {item?.displayName || item?.title || item?.description || 'Undefined'}
              </StyledTitle>
            </Link>
          }
          thumbnail={{
            src: item?.src || item?.asset,
            alt: item?.title || item?.description,
          }}
          metadata={<div>Usage Count: {item?.usage_count}</div>}
        />
      );
  }
};
export const AssetUsageGalleryView = ({ reportData = [], zoomValue, assetType = 'image' }) => {
  const getPreviewColumnWidthByAssetType = useMemo(() => {
    switch (assetType) {
      case 'videos':
        return {
          xs: 12,
          sm: 4,
          md: 3,
          xl: 2,
        };
      default:
        return {
          xs: 12,
          sm: 6,
          md: 4,
        };
    }
  }, [assetType]);

  const sortedReportData = useDeepCompareMemo(
    () => (reportData ? [...reportData].sort((a, b) => b?.usageCount - a?.usageCount) : []),
    [reportData],
  );

  return (
    <Grid2 container xs={12} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {sortedReportData?.map((item, index) => (
        <Grid2 {...getPreviewColumnWidthByAssetType} key={item?.pathname ||  item?.id || index}>
          {renderAssetPreview({ zoomValue, item, assetType, index })}
        </Grid2>
      ))}
    </Grid2>
  );
};
