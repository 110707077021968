import React, { useState, useEffect, useMemo } from 'react';
import {
  format,
  intervalToDuration,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
} from 'date-fns';
import {
  useNotices,
  eventCountsApi,
  getChartColorsForToolSlug,
  useDeepCompareMemo,
} from '@clatter/platform';

const generateGroupByCombinations = ({
  filterByInputs,
  selectedToolsWithTotal,
  selectedFilterBy,
  selectedGroupBy,
  data = [],
}) => {
  const generateCombinations = (...arrays) => {
    const combinations = [];

    function generate(currentCombination, arrayIndex) {
      if (arrayIndex === arrays.length) {
        combinations.push(currentCombination);
        return;
      }

      const currentArray = arrays[arrayIndex];

      for (const item of currentArray) {
        generate([...currentCombination, item], arrayIndex + 1);
      }
    }

    generate([], 0);

    return combinations;
  }

  const _getFilterByOptions = (row, customKey = null) => {
    const defaultData = (() => {
      if (selectedFilterBy?.[row]?.value && selectedFilterBy?.[row]?.value !== 'none') {
        return [selectedFilterBy?.[row]];
      }

      const foundFilterByInput = filterByInputs.find(filterByInput => filterByInput.key === row);

      if (!foundFilterByInput) {
        console.error(`filter by input not found: ${row}`);

        return [];
      }

      return (foundFilterByInput.options || []).filter(option => option.value !== 'none');
    })();

    const rowTemp = defaultData.map(defaultDataRow => ({ ...defaultDataRow, key: customKey ? customKey : row }));

    // handle unknown, in case when API returned null values for it
    if (data.some(dataRow => dataRow?._id?.[row] === null)) {
      rowTemp.push({
        key: row,
        label: 'Unassigned',
        value: null,
      });
    }

    return rowTemp;
  };

  const arrays = [];

  selectedGroupBy.forEach(row => {
    if (row === 'tool') {
      const tools = selectedToolsWithTotal.filter(r => r.value !== 'total');

      arrays.push(tools.map(tool => ({ ...tool, key: 'tool' })));

      return;
    }

    if (row === 'template_id') {
      arrays.push(_getFilterByOptions('template', 'template_id'));

      return;
    }

    // handle any other key
    arrays.push(_getFilterByOptions(row));
  });

  return generateCombinations(...arrays);
};

const generateGroupByCombinationLabels = (groupByCombination) => {
  const foundTool = groupByCombination.find(combinationRow => {
    if (combinationRow.key === 'tool') {
      return combinationRow;
    }
  });
  const foundUserGroup = groupByCombination.find(combinationRow => {
    if (combinationRow.key === 'user_group') {
      return combinationRow;
    }
  });
  const foundTemplateId = groupByCombination.find(combinationRow => {
    if (combinationRow.key === 'template_id') {
      return combinationRow;
    }
  });
  const foundContentRepository = groupByCombination.find(combinationRow => {
    if (combinationRow.key === 'content_repository') {
      return combinationRow;
    }
  });
  const foundDownloadSource = groupByCombination.find(combinationRow => {
    if (combinationRow.key === 'download_source') {
      return combinationRow;
    }
  });
  const foundConnection = groupByCombination.find(combinationRow => {
    if (combinationRow.key === 'connection') {
      return combinationRow;
    }
  });
  let name = '', slug = '';

  groupByCombination.forEach((groupByCombinationRow, groupByCombinationRowIndex) => {
    if (groupByCombinationRowIndex > 0) {
      slug += `_${groupByCombinationRow.value}`

      return name += ` - ${groupByCombinationRow.label}`;
    }

    slug += groupByCombinationRow.value

    return name += groupByCombinationRow.label;
  });

  return {
    name: name,
    slug: slug,
    foundTool: foundTool,
    foundUserGroup: foundUserGroup,
    foundTemplateId: foundTemplateId,
    foundContentRepository: foundContentRepository,
    foundDownloadSource: foundDownloadSource,
    foundConnection: foundConnection,
  };
};

const findGroupByCombinationDataCount = ({
  data,
  period,
  dateSuffix,
  foundTool,
  foundUserGroup,
  foundTemplateId,
  foundContentRepository,
  foundDownloadSource,
  foundConnection,
}) => {
  const foundData = data.find(
    (row) => {
      return (period ? `${row._id.date}_${dateSuffix}` === period : true)
        && (foundTool ? row._id.tool === foundTool.value.toUpperCase() : true)
        && (foundUserGroup ? (
          // !== null -> [User, Marketer]
          // === null -> "unknown"
          foundUserGroup.value !== null
            ? (row._id.user_group || []).includes(foundUserGroup.value)
            : row._id.user_group === null
        ) : true)
        && (foundTemplateId ? row._id.template_id === foundTemplateId.value : true)
        && (foundContentRepository ? row._id.content_repository === foundContentRepository.value : true)
        && (foundDownloadSource ? row._id.download_source === foundDownloadSource.value : true)
        && (foundConnection ? row._id.connection === foundConnection.value : true)
    },
  );

  if (!foundData) {
    return 0;
  }

  return foundData.count;
};

const getTotalEventParamsFromSelectedFilterBy = (selectedFilterBy) => {
  let params = {}
  Object.entries(selectedFilterBy).forEach(([key, value]) => {
    if(key === 'date_range') {
      return;
    }

    if (key === 'tool' && !value.some((v) => v.value === 'all')) {
      params.tool = value.map((v) => v.value.toUpperCase());
      return;
    }

    if (key === 'user_group' && value.value !== 'none') {
      params.userGroup = value.value;
    }

    if (key === 'template' && value.value !== 'none') {
      params.templateId = value.value;
    }

    if (key === 'content_repository' && value.value !== 'none') {
      params.contentRepositoryId = value.value;
    }

    if (key === 'download_source' && !value.some((v) => v.value === 'all')) {
      params.downloadSource = value.map((v) => v.value);
      return;
    }

    if (key === 'connection' && value.value !== 'none') {
      params.connection = value.value;
    }

    return false;
  });

  return params;
}

const useEventReports = ({
  eventType,
  label,
  toolsNamesMap = {},
  filterByInputs = [],
  fetchTotal = {},
  fetchTotalByGroups = {},
  fetchByDay = {},
  fetchDetails = {},
  setTotalReportDataByTool = false,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [customSelectedDateRangeOld, setCustomSelectedDateRange] =
    useState(null); // @todo -remove if all reports will be changed to shared filters component
  const [selectedFilterBy, setSelectedFilterBy] = useState(null);
  const [selectedGroupBy, setSelectedGroupBy] = useState([]);
  const [totalReportData, setTotalReportData] = useState(null);
  const [totalReportByGroupData, setTotalReportByGroupData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [detailsReportData, setDetailsReportData] = useState([]);
  const [isDelayedExecution, setIsDelayedExecution] = useState(false);
  const { addNotice } = useNotices();

  const customSelectedDateRange = selectedFilterBy?.date_range?.dateRange || customSelectedDateRangeOld;
  const filterByToolOptions = (
    filterByInputs.find(filterByInput => filterByInput.key === 'tool')?.options || []
  ).filter(option => option.value !== 'all');

  const isGroupByUserGroupSelected = useDeepCompareMemo(() =>
    selectedGroupBy.includes('user_group'), [selectedGroupBy])

  const selectedToolsWithTotal = useMemo(() => {
    // if no group by are applied, we are going to show total on the chart
    if (!selectedGroupBy.length) {
      return [
        {
          value: 'total',
          label: 'Total',
        },
      ];
    }

    if (selectedFilterBy?.tool?.some((tool) => tool.value === 'all')) {
      return [
        {
          value: 'total',
          label: 'Total',
        },
        ...filterByToolOptions,
      ];
    }

    return [
      {
        value: 'total',
        label: 'Total',
      },
      ...(selectedFilterBy?.tool?.filter((tool) => tool.value !== 'all') || []),
    ];
  }, [selectedFilterBy?.tool, selectedGroupBy]);

  const selectedGranularity = useMemo(() => {
    if (
      customSelectedDateRange?.range?.startDate &&
      customSelectedDateRange?.range?.endDate
    ) {
      const duration = intervalToDuration({
        start: new Date(customSelectedDateRange?.range?.startDate),
        end: new Date(customSelectedDateRange?.range?.endDate),
      });

      if (duration?.months > 1 && duration?.months <= 6) {
        return {
          value: 'week',
          title: 'Week',
          periods: eachWeekOfInterval(
            {
              start: new Date(customSelectedDateRange?.range?.startDate),
              end: new Date(customSelectedDateRange?.range?.endDate),
            },
            { weekStartsOn: 1 },
          ).map((period) => `${format(period, 'yyyy-II')}_weekly`),
        };
      }

      if (duration?.months > 6 || duration.years >= 1) {
        return {
          value: 'month',
          title: 'Month',
          periods: eachMonthOfInterval({
            start: new Date(customSelectedDateRange?.range?.startDate),
            end: new Date(customSelectedDateRange?.range?.endDate),
          }).map((period) => `${format(period, 'yyyy-MM')}_monthly`),
        };
      }

      return {
        value: 'day',
        title: 'Day',
        periods: eachDayOfInterval({
          start: new Date(customSelectedDateRange?.range?.startDate),
          end: new Date(customSelectedDateRange?.range?.endDate),
        }).map((period) => `${format(period, 'yyyy-MM-dd')}_daily`),
      };
    }

    return {
      value: 'day',
      title: 'Day',
    };
  }, [
    customSelectedDateRange?.range?.startDate,
    customSelectedDateRange?.range?.endDate,
  ]);

  const dateSuffix = useMemo(() => {
    if (selectedGranularity.value === 'month') {
      return 'monthly';
    }

    if (selectedGranularity.value === 'week') {
      return 'weekly';
    }

    return 'daily';
  }, [selectedGranularity?.value]);

  const totalChartLabel = useMemo(
    () => `${label} by ${selectedGranularity?.title || 'Day'}`,
    [selectedGranularity?.title],
  );

  // 'fetchData' props:
  // {
  //    filterByInputs,
  //    eventType,
  //    fetchTotal,
  //    toolsNamesMap
  //    selectedGroupBy,
  //    selectedFilterBy,
  //    selectedGranularity,
  //    selectedToolsWithTotal,
  //    customSelectedDateRange,
  // }
  const fetchData = async () => {
    if (customSelectedDateRange?.range?.startDate && customSelectedDateRange?.range?.endDate) {
      const { startDate, endDate } = customSelectedDateRange?.range;
      const dateSuffix = (() => {
        if (selectedGranularity.value === 'month') {
          return 'monthly';
        }

        if (selectedGranularity.value === 'week') {
          return 'weekly';
        }

        return 'daily';
      })();

      //region GET TOTAL
      if (fetchTotal) {
        // fetch total (regardless of the selected group by)
        let getTotalEventCountsParams = {
          eventType: eventType,
          startDate: startDate.split(' ')[0],
          endDate: endDate.split(' ')[0],
        };

        // filter by (defined on the report-level)
        if (fetchTotal?.filterBy) {
          Object.entries(fetchTotal.filterBy).forEach(([key, value]) => {
            if (!value) {
              return false;
            }

            return (getTotalEventCountsParams[key] = value);
          });
        }

        // filter by (dynamic, based on the user selections)
        if (selectedFilterBy) {
          getTotalEventCountsParams = {
            ...getTotalEventCountsParams,
            ...getTotalEventParamsFromSelectedFilterBy(selectedFilterBy),
          }
        }

        // unique by
        if (fetchTotal?.uniqueBy) {
          getTotalEventCountsParams.uniqueBy = fetchTotal.uniqueBy;
        }

        const getTotalEventCountsResponse = await eventCountsApi.getEventCounts(getTotalEventCountsParams);

        if (getTotalEventCountsResponse && getTotalEventCountsResponse.data) {
          if (setTotalReportDataByTool) {
            const totalReportDataByTool = {
              total: {
                count: getTotalEventCountsResponse?.data?.[0]?.count || 0,
              },
            };

            Object.keys(toolsNamesMap).forEach((toolSlug) => {
              const foundToolCount = getTotalEventCountsResponse?.data.find(
                (row) => row?._id?.tool?.toUpperCase() === toolSlug.toUpperCase(),
              );

              totalReportDataByTool[toolSlug] = {
                count: foundToolCount ? foundToolCount.count : 0,
              };
            });
            setTotalReportData(totalReportDataByTool);
          } else {
            setTotalReportData({
              count: getTotalEventCountsResponse?.data[0]?.count,
            });
          }
        }

        // fetch total by group (if any selected, i.e. totals by { tool, user_group })
        if (selectedGroupBy.length) {
          let getTotalEventCountsByGroupByParams = {
            eventType: eventType,
            startDate: startDate.split(' ')[0],
            endDate: endDate.split(' ')[0],
            groupBy: selectedGroupBy,
          };

          // filter by (defined on the report-level)
          if (fetchTotalByGroups?.filterBy) {
            Object.entries(fetchTotalByGroups.filterBy).forEach(([key, value]) => {
              if (!value) {
                return false;
              }

              return (getTotalEventCountsByGroupByParams[key] = value);
            });
          }

          // filter by (dynamic, based on the user selections)
          if (selectedFilterBy) {
            getTotalEventCountsByGroupByParams = {
              ...getTotalEventCountsByGroupByParams,
              ...getTotalEventParamsFromSelectedFilterBy(selectedFilterBy),
            }
          }

          // group by (defined on the report-level)
          if (fetchTotalByGroups.groupByAdditionalFields) {
            getTotalEventCountsByGroupByParams.groupByAdditionalFields = fetchTotalByGroups.groupByAdditionalFields;
          }

          // unique by
          if (fetchTotal?.uniqueBy) {
            getTotalEventCountsByGroupByParams.uniqueBy = fetchTotal.uniqueBy;
          }

          const getTotalEventCountsByGroupByResponse = await eventCountsApi.getEventCounts(
            getTotalEventCountsByGroupByParams,
          );

          if (getTotalEventCountsByGroupByResponse && getTotalEventCountsByGroupByResponse.data) {
            const groupByCombinations = generateGroupByCombinations({
              filterByInputs,
              selectedToolsWithTotal,
              selectedFilterBy,
              selectedGroupBy,
              data: getTotalEventCountsByGroupByResponse.data,
            });
            const totalEventCountsByGroupData = groupByCombinations.map((combination, combinationIndex) => {
              const {
                name,
                foundTool,
                foundUserGroup,
                foundTemplateId,
                foundContentRepository,
                foundDownloadSource,
                foundConnection,
              } = generateGroupByCombinationLabels(combination);
              const foundDataCount = findGroupByCombinationDataCount({
                data: getTotalEventCountsByGroupByResponse.data,
                dateSuffix: dateSuffix,
                foundTool: foundTool,
                foundUserGroup: foundUserGroup,
                foundTemplateId: foundTemplateId,
                foundContentRepository: foundContentRepository,
                foundDownloadSource: foundDownloadSource,
                foundConnection: foundConnection,
              });

              return {
                id: combinationIndex,
                name: name,
                count: foundDataCount,
              };
            });

            // filter out (hide) rows (group) with 0 counts
            setTotalReportByGroupData(totalEventCountsByGroupData.filter(item => item?.count > 0));
          }
        } else {
          setTotalReportByGroupData(null);
        }
      }
      //endregion

      //region GET BY DAY
      if (fetchByDay) {
        let getEventCountsByDayParams = {
          eventType: eventType,
          startDate: startDate.split(' ')[0],
          endDate: endDate.split(' ')[0],
          groupBy: selectedGranularity.value,
          sortBy: '_id.date',
          sortOrder: 'desc',
        };

        // filter by (defined on the report-level)
        if (fetchByDay?.filterBy) {
          Object.entries(fetchByDay.filterBy).forEach(([key, value]) => {
            if (!value) {
              return false;
            }

            return (getEventCountsByDayParams[key] = value);
          });
        }

        // filter by (dynamic, based on the user selections)
        if (selectedFilterBy) {
          getEventCountsByDayParams = {
            ...getEventCountsByDayParams,
            ...getTotalEventParamsFromSelectedFilterBy(selectedFilterBy),
          }
        }

        if (fetchByDay?.uniqueBy) {
          getEventCountsByDayParams.uniqueBy = fetchByDay.uniqueBy;
        }

        // @todo: promise.all
        const getEventCountsByDayResponse = await eventCountsApi.getEventCounts(getEventCountsByDayParams);
        let getEventCountsByDayByGroupResponse;

        if (selectedGroupBy.length) {
          getEventCountsByDayByGroupResponse = await eventCountsApi.getEventCounts({
            ...getEventCountsByDayParams,
            groupBy: [selectedGranularity.value, ...selectedGroupBy],
          });
        }

        if (getEventCountsByDayResponse && getEventCountsByDayResponse.data) {
          const reportData = [
            {
              slug: 'total',
              title: 'Total',
              data: {
                labels: selectedGranularity.periods,
                datasets: [
                  {
                    toolName: 'Total',
                    label: `Total ${label}`,
                    data: selectedGranularity.periods.map((period) => {
                      const foundData = getEventCountsByDayResponse.data.find(
                        (row) => `${row._id.date}_${dateSuffix}` === period,
                      );

                      if (!foundData) {
                        return 0;
                      }

                      return foundData.count;
                    }),
                    borderColor: 'rgb(44,56,197)',
                    backgroundColor: 'rgb(44,56,197)',
                    radius: 2,
                    borderWidth: 2,
                    hoverRadius: 7,
                    fill: false,
                  },
                ],
              },
            },
          ];

          if (getEventCountsByDayByGroupResponse && getEventCountsByDayByGroupResponse.data) {
            // generate group by combinations, e.g. { tool1 - user group1, tool1, - user group2, ... }
            const groupByCombinations = generateGroupByCombinations({
              filterByInputs,
              selectedToolsWithTotal,
              selectedFilterBy,
              selectedGroupBy,
              data: getEventCountsByDayByGroupResponse.data,
            });

            groupByCombinations.forEach((combination) => {
              const {
                name,
                slug,
                foundTool,
                foundUserGroup,
                foundTemplateId,
                foundContentRepository,
                foundDownloadSource,
                foundConnection,
              } = generateGroupByCombinationLabels(combination);

              return reportData.push({
                slug: slug,
                title: name,
                data: {
                  labels: selectedGranularity.periods,
                  datasets: [
                    {
                      toolName: name,
                      label: label,
                      data: selectedGranularity.periods.map((period) =>
                        findGroupByCombinationDataCount({
                          data: getEventCountsByDayByGroupResponse.data,
                          period: period,
                          dateSuffix: dateSuffix,
                          foundTool: foundTool,
                          foundUserGroup: foundUserGroup,
                          foundTemplateId: foundTemplateId,
                          foundContentRepository: foundContentRepository,
                          foundDownloadSource: foundDownloadSource,
                          foundConnection: foundConnection,
                        }),
                      ),
                      borderColor: getChartColorsForToolSlug(slug)?.mauColor,
                      backgroundColor: getChartColorsForToolSlug(slug)?.mauColor,
                      radius: 2,
                      borderWidth: 2,
                      hoverRadius: 7,
                      fill: false,
                    },
                  ],
                },
              });
            });
          }

          setReportData(reportData);
        }
      }
      //endregion

      //region GET DETAILS
      if (fetchDetails) {
        const details = {};

        // define "global" (to this details scope) params
        let getEventDetailsGlobalParams = {
          eventType: eventType,
          startDate: startDate.split(' ')[0],
          endDate: endDate.split(' ')[0],
        };

        // filter by (defined on the report-level)
        if (fetchDetails?.filterBy) {
          Object.entries(fetchDetails.filterBy).forEach(([key, value]) => {
            if (!value) {
              return false;
            }

            return (getEventDetailsGlobalParams[key] = value);
          });
        }

        // filter by (dynamic, based on the user selections)
        if (selectedFilterBy) {
          getEventDetailsGlobalParams = {
            ...getEventDetailsGlobalParams,
            ...getTotalEventParamsFromSelectedFilterBy(selectedFilterBy),
          }
        }

        // cover the { who } portion of details
        if (fetchDetails.who) {
          let getEventDetailsWhoParams = { ...getEventDetailsGlobalParams };

          // filter by (defined on the report-level)
          if (fetchDetails?.who?.filterBy) {
            Object.entries(fetchDetails?.who?.filterBy).forEach(([key, value]) => {
              if (!value) {
                return false;
              }

              return (getEventDetailsWhoParams[key] = value);
            });
          }

          // filter by (dynamic, based on the user selections)
          if (selectedFilterBy) {
            getEventDetailsWhoParams = {
              ...getEventDetailsWhoParams,
              ...getTotalEventParamsFromSelectedFilterBy(selectedFilterBy),
            }
          }

          if (fetchDetails.who.groupBy) {
            getEventDetailsWhoParams.groupBy = fetchDetails.who.groupBy;
          }

          if (fetchDetails.who.groupByAdditionalFields) {
            getEventDetailsWhoParams.groupByAdditionalFields = fetchDetails.who.groupByAdditionalFields;
          }

          if(isGroupByUserGroupSelected) {
            getEventDetailsWhoParams.groupBy = [...getEventDetailsWhoParams?.groupBy, 'user_group'];
          }

          const getEventDetailsResponse = await eventCountsApi.getEventCounts(getEventDetailsWhoParams);

          if (getEventDetailsResponse && getEventDetailsResponse.data) {
            details.who = getEventDetailsResponse.data;
          }
        }

        // cover the { what } portion of details
        if (fetchDetails.what) {
          const getEventDetailsWhatParams = { ...getEventDetailsGlobalParams };

          if (fetchDetails.what.groupBy) {
            getEventDetailsWhatParams.groupBy = fetchDetails.what.groupBy;
          }

          if (fetchDetails.what.groupByAdditionalFields) {
            getEventDetailsWhatParams.groupByAdditionalFields = fetchDetails.what.groupByAdditionalFields;
          }

          if (fetchDetails.what.responseMode) {
            getEventDetailsWhatParams.responseMode = fetchDetails.what.responseMode;
          }

          const getEventDetailsResponse = await eventCountsApi.getEventCounts(getEventDetailsWhatParams);

          if (getEventDetailsResponse && getEventDetailsResponse.data) {
            details.what = getEventDetailsResponse.data;
          }
        }

        setDetailsReportData(details);
      }
      //endregion
    }
  };

  const handleDatePickerChange = ({ dateRange }) => setCustomSelectedDateRange(dateRange);

  const handleFilterByChange = async (values) => {
    setSelectedFilterBy(values);
    setIsDelayedExecution(true);
  };

  const handleGroupByChange = async (values) => {
    setSelectedGroupBy(values);
    setIsDelayedExecution(true);
  };

  // this is required to properly handle
  // concurrency in useState changes...
  useEffect(() => {
    let timeoutId;

    if (isDelayedExecution) {
      timeoutId = setTimeout(async () => {
        setIsLoading(true);
        try {
          await fetchData();
        } catch (error) {
          console.debug("ERROR", error);
          addNotice({
            message: error.response?.data?.message || `Error getting report data`,
            type: 'error',
            title: 'Error',
          });
        } finally {
          setIsLoading(false);
        }
        setIsDelayedExecution(false);
      }, 300);
    }

    return () => {
      // Clear the timeout if the component is unmounted or if isDelayedExecution changes
      clearTimeout(timeoutId);
    };
  }, [
    selectedGroupBy,
    selectedFilterBy,
    isDelayedExecution,
    customSelectedDateRange?.range?.endDate,
    customSelectedDateRange?.range?.startDate,
  ]);
  return {
    isLoading,
    handleDatePickerChange,
    handleFilterByChange,
    handleGroupByChange,
    customSelectedDateRange,
    selectedFilterBy,
    selectedGroupBy,
    selectedGranularity,
    selectedToolsWithTotal,
    dateSuffix,
    totalChartLabel,
    totalReportData,
    totalReportByGroupData,
    reportData,
    detailsReportData,
  };
};

export default useEventReports;
