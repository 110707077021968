import React from 'react';
import styled from 'styled-components';
import FileUpload from '../FileUpload/FileUpload';
import { IconButton } from '@clatter/ui';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const formatFileSize = (fileSize, format = 'KB') => {
  switch (format) {
    case 'KB':
      return `${Math.floor(fileSize / 1000)} KB`;
    case 'MB':
      return `${Math.floor(fileSize / 1000000)} MB`;
    default:
      return `${fileSize} B`;
  }
};

const StyledImageUpload = styled.div`
  .image-preview {
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    grid-template-rows: 150px;
    gap: 16px;
    padding-bottom: 16px;

    .image-preview-item {
      position: relative;

      .image-preview-image {
        height: 126px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .image-preview-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 24px;

        .file-name {
          font-size: 12px;
          max-width: 60%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .file-size {
          font-size: 10px;
        }
      }

      .image-preview-button {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }
`;

const ImageUpload = ({
  acceptedFileTypes = 'image/*',
  multiple = false,
  onChange,
  value = [],
  single,
}) => {
  const renderPreview = (files, handleRemoveFileClick) => {
    if ((Array.isArray(files) && files.length === 0) || !files) {
      return null;
    }

    const iterateFiles = Array.isArray(files) ? files : [files];

    return (
      <div className="image-preview">
        {iterateFiles.map((file, index) => (
          <div className="image-preview-item" key={`${file.name}-${file.size}`}>
            <div
              className="image-preview-image"
              style={{ backgroundImage: `url(${URL.createObjectURL(file)}` }}
            />
            <div className="image-preview-details">
              <div className="file-name">{file.name}</div>
              <div className="file-size">{formatFileSize(file.size)}</div>
            </div>

            <IconButton
              className="image-preview-button"
              data-file-index={index}
              onClick={handleRemoveFileClick}
            >
              <ClearOutlinedIcon />
            </IconButton>
          </div>
        ))}
      </div>
    );
  };

  return (
    <StyledImageUpload>
      <FileUpload
        acceptedFileTypes={acceptedFileTypes}
        multiple={multiple}
        onChange={onChange}
        previewOnTop
        renderCustomPreview={renderPreview}
        value={value}
        single={single}
      />
    </StyledImageUpload>
  );
};

export default ImageUpload;
