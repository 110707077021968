import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Stack, Box, Fade, LinearProgress } from '@mui/material';

import { Dialog } from '@clatter/ui';
import { useInterval, presentationBuildSteps } from '@clatter/platform';

export const ANIMATION_DURATION = 500;

const StyledEllipsisAnimation = styled.span`
  .dot1 {
    animation: visibility 3s linear infinite;
  }

  @keyframes visibility {
    0% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .dot2 {
    animation: visibility2 3s linear infinite;
  }

  @keyframes visibility2 {
    0% {
      opacity: 0;
    }
    21% {
      opacity: 0;
    }
    22% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .dot3 {
    animation: visibility3 3s linear infinite;
  }

  @keyframes visibility3 {
    0% {
      opacity: 0;
    }
    43% {
      opacity: 0;
    }
    44% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

const StyledEllipsis = () => (
  <StyledEllipsisAnimation>
    <span className="dot1">.</span>
    <span className="dot2">.</span>
    <span className="dot3">.</span>
  </StyledEllipsisAnimation>
);

const StyledContent = styled(Box)`
  width: 500px;
  max-width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;

  .status {
    height: 200px;
    display: flex;
    align-items: center;
    font-size: 25px;
    justify-content: center;
  }
`;

const StyledText = styled.p`
  font-size: 25px;
  font-weight: 500;
  text-align: center;
`;

const steps = {
  [presentationBuildSteps.init]: {
    index: 0,
    label: 'Initialization',
    progressStart: 0,
  },
  [presentationBuildSteps.templateProcessing]: {
    index: 1,
    label: 'Template Processing',
    progressStart: 20,
  },
  [presentationBuildSteps.assemblingSlides]: {
    index: 2,
    label: 'Assembling Slides',
    progressStart: 40,
  },
  [presentationBuildSteps.toc]: {
    index: 3,
    label: 'Generating Table Of Contents',
    progressStart: 60,
  },
  [presentationBuildSteps.finishing]: {
    index: 4,
    label: 'Finishing up',
    progressStart: 80,
  },
};

const BorderLinearProgress = styled(LinearProgress)`
  height: 10px !important;
  border-radius: 5px;
  background-color: #9e9e9e33 !important; // progress background color
  .MuiLinearProgress-bar {
    background-color: ${({ theme }) =>
      theme.palette.primaryColor}; // progress color
  }
`;

const BuildPresentationLoader = ({ step = presentationBuildSteps.init }) => {
  const [animation, setAnimation] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState({
    label: '',
    index: 0,
  });

  useInterval(
    () => setProgress((prev) => prev + 2),
    progress >= 100 ? null : 1500,
  );

  useEffect(() => {
    const updateStep = () => {
      setAnimation(true);
      setProgress(Math.max(progress, steps[step]?.progressStart));
      setCurrentStep({
        label: steps[step]?.label,
        index: steps[step]?.index,
      });
    };

    if (step && step !== presentationBuildSteps.init) {
      setAnimation(false);

      setTimeout(updateStep, ANIMATION_DURATION);
    } else {
      updateStep();
    }
  }, [step]);

  useEffect(() => {
    return () => {
      setProgress(0);
    };
  }, []);

  return (
    <Dialog title="Build process" open>
      <StyledContent>
        <Stack
          justifyContent="center"
          sx={{ width: '100%', height: '100%' }}
          spacing={4}
        >
          <BorderLinearProgress variant="determinate" value={progress} />
          <Fade timeout={ANIMATION_DURATION} in={animation}>
            <StyledText>
              {currentStep.label}
              <StyledEllipsis />
            </StyledText>
          </Fade>
        </Stack>
      </StyledContent>
    </Dialog>
  );
};

BuildPresentationLoader.propTypes = {
  value: (props, propName, componentName) => {
    if (props[propName] < 0 || props[propName] > 100) {
      return new Error(
        `Invalid prop ${propName} value (${props[propName]}) supplied to ${componentName}. Validation failed.`,
      );
    }
  },
  status: PropTypes.string,
};

export default BuildPresentationLoader;
