import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const dashboardApi = {
  getDailyUtilizationRate: ({ params }) =>
    getAxiosApiV2WithAuthorization({
      url: '/daily-utilization-rate',
      params: params,
    }),
};

export default dashboardApi;
