import React, { useMemo } from 'react';

const getSeparator = (url) => (url && url?.includes('?') ? '&' : '?');
const VideoGalleryIframe = ({ videoUrl }) => {
  const updatedVideoUrl = useMemo(() => {
    if (!videoUrl) {
      return '';
    }

    // let's create copy of videoUrl to not directly modify it
    let updatedUrl = `${videoUrl}`;

    const hasControls = videoUrl.includes('controls');
    const hasTransparent = videoUrl.includes('transparent');

    if (!hasControls) {
      updatedUrl += `${getSeparator(updatedUrl)}controls=0`;
    }

    if (!hasTransparent) {
      updatedUrl += `${getSeparator(updatedUrl)}transparent=0`;
    }

    return updatedUrl;
  }, [videoUrl]);

  return (
    <iframe
      src={updatedVideoUrl}
      frameBorder="0"
      allow="fullscreen; picture-in-picture"
      allowFullScreen
      scrolling="no"
    />
  );
};

export default VideoGalleryIframe;
