import React, { useEffect, useState } from 'react';
import {
  Button,
  buttonVariants,
  Dialog,
  FileUpload,
  FormControl,
  Loader,
} from '../../index';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { exportImportApi, useNotices, downloadBlobFile } from '@clatter/platform';

const StyledImportForm = styled.form`
  width: 480px;
`;

const zipValidationSchema = Joi.object().keys({
  file: Joi.any()
    .required()
    .custom((value, helper) => {
      if (!value) {
        return helper.message('Please provide a zip file.');
      }

      if (value && !value.type.includes('zip')) {
        return helper.message('Invalid file type!');
      }

      return value;
    }),
});

const csvValidationSchema = Joi.object().keys({
  file: Joi.any()
    .required()
    .custom((value, helper) => {
      if (!value) {
        return helper.message('Please provide a CSV file.');
      }

      if (value && !value.type.includes('csv')) {
        return helper.message('Invalid file type!');
      }

      return value;
    }),
});

const getValidationSchema = (fileType) => {
  switch (fileType) {
    case 'zip':
      return zipValidationSchema;
    case 'csv':
      return csvValidationSchema;
    default:
      return zipValidationSchema;
  }
};

const getContentType = (fileType) => {
  switch (fileType) {
    case 'zip':
      return 'application/zip';
    case 'csv':
      return 'text/csv';
    default:
      return 'application/zip';
  }
};
const ExportImportData = ({
  exportUrl,
  exportDisabled,
  onImportSuccessCallback,
  exportFileName,
  showExportButton = true,
  importFileType = 'zip',
  collectionName = null,
}) => {
  const { addNotice } = useNotices();
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      file: null,
    },
    resolver: joiResolver(getValidationSchema(importFileType)),
  });

  const {
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = formMethods;

  const toggleShowImportDialog = () => {
    setShowImportDialog(!showImportDialog);
  };

  const onSubmit = async (formFields) => {
    setLoading(true);
    clearErrors();
    try {
      const response = await exportImportApi.importData({
        url: exportUrl,
        file: formFields.file,
      });

      onImportSuccessCallback && onImportSuccessCallback(response);
      setShowImportDialog(false);
    } catch (error) {
      setError('file', {
        type: 'custom',
        message: error?.response?.data?.message || 'Error during file processing.',
      });
    }
    setLoading(false);
  };

  const handleExportClick = async (exportFileType) => {
    if (confirm('Export data?')) {
      setLoading(true);
      try {
        const exportedFile = await exportImportApi.exportData({
          url: exportUrl,
          contentType: getContentType(exportFileType),
        });

        const collectionMessage = collectionName ? `for "${collectionName}"` : '';

        addNotice({
          message: `Data ${collectionMessage} has been exported successfully`
            + ` to file: ${exportFileName}.${exportFileType}.`,
          type: 'success',
          title: 'Success',
        });

        return downloadBlobFile(exportedFile, `${exportFileName}.${exportFileType}`);
      } catch (error) {
        const parsedErrorMessage = JSON.parse(await error?.response?.data?.text() || '{}')?.message;

        addNotice({
          message: parsedErrorMessage || 'Error exporting data.',
          type: 'error',
          title: 'Error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // reset the form when closing dialog
    if (!showImportDialog) {
      reset();
    }
  }, [showImportDialog]);

  return (
    <>
      {loading && <Loader />}
      <Button variant={buttonVariants.clear} onClick={toggleShowImportDialog}>
        Import
      </Button>

      {showExportButton && (
        <Button
          variant={buttonVariants.clear}
          disabled={exportDisabled}
          onClick={() => handleExportClick('zip')}
        >
          Export
        </Button>
      )}

      {showExportButton && (
        <Button
          variant={buttonVariants.clear}
          disabled={exportDisabled}
          onClick={() => handleExportClick('csv')}
        >
          Export to CSV
        </Button>
      )}

      <Dialog
        title="Import data"
        onCloseDialog={toggleShowImportDialog}
        open={showImportDialog}
      >
        <StyledImportForm onSubmit={handleSubmit(onSubmit)}>
          <FormControl error={errors.file}>
            <Controller
              name="file"
              render={({ field: { onChange, value } }) => (
                <FileUpload onChange={onChange} value={value} />
              )}
              control={control}
            />
          </FormControl>
          <Button disabled={!isValid || !isDirty} type="submit">
            Submit
          </Button>
        </StyledImportForm>
      </Dialog>
    </>
  );
};

export default ExportImportData;
