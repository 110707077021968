import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '../IconButton/IconButton';

const StyledDialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  .dialog {
    background-color: #fbfbfb;
    min-width: 320px;
    max-width: 80%;
    border-radius: 4px;

    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      border-bottom: 1px solid #ddd;
      padding: 0 16px;

      .dialog-title {
        flex-grow: 0;
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .full-title {
        max-width: 100%;
      }

      &.no-border {
        border-bottom: none;
      }
    }

    .dialog-content {
      padding: 16px;
      max-height: 80vh;
      overflow-y: auto;
    }

    #dialog-footer {
      padding: 5px 16px 16px;
    }
  }
`;

const Dialog = ({ children, title, onCloseDialog, open, fullTitle = false, includeFooter = false, noBorder = false }) => {
  const dialogRef = useRef(null);
  if (!open) {
    return null;
  }

  const handleClickOutsideDialog = (event) => {
    if (dialogRef.current && dialogRef.current === event.target && onCloseDialog) {
      onCloseDialog(null);
    }
  };

  return ReactDOM.createPortal(
    <StyledDialog onMouseDown={handleClickOutsideDialog} ref={dialogRef}>
      <div className="dialog">
        <div className={classNames('dialog-header', { 'no-border': noBorder })}>
          <div className={classNames('dialog-title', { 'full-title': fullTitle })}>{title}</div>
          {onCloseDialog && (
            <IconButton onClick={onCloseDialog}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <div className="dialog-content">{children}</div>
        {includeFooter && <div id="dialog-footer"></div>}
      </div>
    </StyledDialog>,
    document.body,
  );
};

export default Dialog;
