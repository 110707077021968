import React from 'react';
import DataGridPremium from '../../../DataGridPremium/DataGridPremium';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import { handleExportAsCSV } from '@clatter/platform';
import { buttonWidths, IconButton } from '@clatter/ui';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';

export const AssetUsageListView = ({
  columns,
  isLoading,
  reportData,
  defaultSortField,
  listViewTableName,
  listViewExportName,
}) => {
  const CustomToolbar = ({ exportFileName }) => {
    const apiRef = useGridApiContext();

    return (
      <GridToolbarContainer
        style={{
          justifyContent: 'end',
        }}
      >
        <IconButton
          tooltip="Export"
          width={buttonWidths.md}
          onClick={() =>
            handleExportAsCSV({
              apiRef: apiRef,
              exportFileName: exportFileName,
            })
          }
        >
          <CloudDownloadIcon />
        </IconButton>
      </GridToolbarContainer>
    );
  };

  return (
    <DataGridPremium
      loading={isLoading}
      getRowId={(row) => row._id}
      name={listViewTableName}
      dataGridProps={{
        components: {
          Toolbar: () =>
            CustomToolbar({
              exportFileName: listViewExportName,
            }),
        },
      }}
      rows={reportData}
      columns={columns}
      exportFileName={listViewExportName}
      defaultSortField={defaultSortField}
    />
  );
};
