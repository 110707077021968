import React, { useMemo } from 'react';
import styled from "styled-components";
import { Dialog } from '@clatter/ui';


const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (height/width * 100) */
  min-width: 60vw;

  @media (min-width: 1110px) {
    min-width: 640px;
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const getSeparator = (url) => (url && url?.includes('?') ? '&' : '?');

const VideoPreviewDialog = ({ title, videoUrl, onCloseDialog, open, iframeStyles = null }) => {
  const updatedVideoUrl = useMemo(() => {
    if (!videoUrl) {
      return '';
    }

    // let's create copy of videoUrl to not directly modify it
    let updatedUrl = `${videoUrl}`;

    const hasAutoplay = videoUrl.includes('autoplay');
    const hasAutoPause = videoUrl.includes('autopause');

    if (!hasAutoplay) {
      updatedUrl += `${getSeparator(updatedUrl)}autoplay=1`
    }

    if (!hasAutoPause) {
      updatedUrl += `${getSeparator(updatedUrl)}autopause=0`
    }

    return updatedUrl

  }, [videoUrl])

  if (!videoUrl || !title) {
    return null;
  }

  return (
    <Dialog title={title} onCloseDialog={onCloseDialog} open={open}>
      <StyledContainer>
        <StyledIframe
          style={iframeStyles || { display: 'block' }}
          src={updatedVideoUrl}
          frameBorder="0"
          allow="fullscreen; picture-in-picture; autoplay"
          allowFullScreen
          title={title || 'Video Preview'}
        />
      </StyledContainer>
    </Dialog>
  );
};

export default VideoPreviewDialog;
