import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const usersApi = {
  fetchUsers: () =>
    getAxiosApiV2WithAuthorization({
      url: `/user`,
      method: 'get',
    }),
  fetchUser: ({ userId }) =>
    getAxiosApiV2WithAuthorization({
      url: `/user/${userId}`,
      method: 'get',
    }),
  createUser: ({ data }) =>
    getAxiosApiV2WithAuthorization({
      url: `/user`,
      method: 'POST',
      data: data,
    }),
  importUsers: ({ data }) =>
    getAxiosApiV2WithAuthorization({
      url: `/user/import`,
      method: 'POST',
      data: data,
    }),
  updateUser: ({ userId, data }) =>
    getAxiosApiV2WithAuthorization({
      url: `/user/${userId}`,
      method: 'PATCH',
      data: data,
    }),
  sendVerificationEmail: ({ userId }) =>
    getAxiosApiV2WithAuthorization({
      url: `/user/${userId}/email-verify-requests`,
      method: 'POST',
    }),
  deleteUser: ({ userId }) =>
    getAxiosApiV2WithAuthorization({
      url: `/user/${userId}`,
      method: 'DELETE',
    }),
};

export default usersApi;
