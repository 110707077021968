import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { Divider, Stack } from '@mui/material';
import { Checkbox, DateAndGranularityPicker } from '@clatter/ui';
import { useRoutesState } from "@clatter/platform";
import ToolsSelector from './Components/ToolsSelector';

export const REPORT_FILTERS_SECTION_KEY = 'filters';

export const ReportFiltersSection = ({
  label = "Filter By",
  inputs = [],
  onChange,
  isLoading,
  selectedDateRange,
  extraInputs = null,
  showGranularity = false,
  showDateRangePicker = false,
  showCustomDateRange = true,
  isGroupBy = false,
  labelMinWidth = 80,
  dateRangeOptions,
}) => {
  const { setRouteStateProperty, getRouteStateProperty } = useRoutesState();

  const initialState = groupBy(inputs, 'key');
  for (let itemKey in initialState) {
    const itemData = initialState[itemKey][0];
    initialState[itemKey] = itemData?.isMultiSelect ? [itemData?.options?.[0]] : itemData?.options?.[0];
  }

  const [selected, setSelected] = useState(() =>
    getRouteStateProperty(REPORT_FILTERS_SECTION_KEY) || initialState);

  //region Methods
  const handleDatePickerChange = (value) => {
    setSelected((prev) => ({
      ...prev,
      date_range: value,
    }));
  };

  const handleCheckboxChange = (value) => {
    console.debug('handleCheckboxChange: ', value);
  };

  const handleSelectChange = (key, value) => {
    setSelected((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  //endregion

  useEffect(() => {
    // persist selection
    setRouteStateProperty({ [REPORT_FILTERS_SECTION_KEY]: selected });
    // emit data to parent component
    onChange(selected);
  }, [selected, isGroupBy]);

  return (
    <Stack key={isGroupBy ? 'group' : 'filters'} direction="column" spacing={2}>
      <Divider textAlign="left">{label}</Divider>

      <div>
        {extraInputs}

        {showDateRangePicker && (
          <DateAndGranularityPicker
            disabled={isLoading}
            showCustomDateRange={showCustomDateRange}
            showGranularity={showGranularity}
            onChange={handleDatePickerChange}
            customSelectedDateRange={selectedDateRange}
            defaultDateRangeValue={selected?.date_range?.dateRange?.value}
            customDateRangeOptions={{
              maxDate: new Date(),
            }}
            labelMinWidth={labelMinWidth}
            dateRangeOptions={dateRangeOptions}
            alignLeft
          />
        )}

        {inputs?.map((item, index) => {
          return (
            <React.Fragment key={`${index}-selector`}>
              {item?.type === 'checkbox' && <Checkbox onChange={handleCheckboxChange} text={item?.label} />}
              {item?.type === 'select' && (
                <ToolsSelector
                  disabled={isLoading}
                  labelMinWidth={labelMinWidth}
                  label={item?.label}
                  options={item?.options}
                  maxWidth={item?.maxWidth}
                  isMulti={item?.isMultiSelect}
                  placeholder={item?.placeholder}
                  getChipColors={item?.getChipColors}
                  selectedTools={selected?.[item?.key]}
                  selectAllOption={item?.selectAllOption}
                  defaultOptionValue={item?.defaultOptionValue}
                  isClearable={item?.isClearable}
                  onChange={(value) => {
                    handleSelectChange(item?.key, value);
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </Stack>
  );
};
