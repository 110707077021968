import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { platformSelectors } from '@clatter/platform';

const StyledIframeContainer = styled.div`
  margin-top: -40px;

  iframe {
    width: 100%;
    height: 240vh;
  }
`

const AnalyticsView = () => {
  const analyticsConfig = useSelector(platformSelectors.toolInfo.selectToolInfo)?.data?.analyticsConfig;
  const isPlausibleEnabled = analyticsConfig?.plausible?.enabled;
  const plausibleEmbedUrl = analyticsConfig?.plausible?.embed_url;
  const search = window.location.search?.replace('?', '&');
  const plausibleUrl = search ? `${plausibleEmbedUrl}${search}` : plausibleEmbedUrl

  return (
    <StyledIframeContainer>
      {isPlausibleEnabled && plausibleUrl && (
        <>
          <iframe
            plausible-embed="true"
            src={plausibleUrl}
            frameBorder="0"
            loading="lazy"
            allow-scripts="true"
            allow-same-origin="true"
            allow="clipboard-read; clipboard-write"
          ></iframe>
          <script async src="https://plausible.io/js/embed.host.js"></script>
        </>
      )}
    </StyledIframeContainer>
  );
};

export default AnalyticsView;
