import { platformSelectors } from '@clatter/platform';
import { useSelector } from 'react-redux';
import ErrorBoundaryMessage from './ErrorBoundaryMessage';

const ApiUnavailableErrorBoundary = ({ children }) => {
  const error = useSelector(platformSelectors.globalError.getError);
  return error ? <ErrorBoundaryMessage /> : children;
};

export default ApiUnavailableErrorBoundary;
