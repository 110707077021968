import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.linkColor};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.palette.linkHoverColor};
  }
`;

export default StyledLink;
