import React from 'react';
import styled from 'styled-components';

const StyledResourceItem = styled.a`
  span {
    display: block;
    font-size: ${({ theme }) => theme?.linkResources?.item?.fontSize || '15px'};
    font-family: ${({ theme }) => theme?.body?.fontFamily || 'sans-serif, Arial'};
    color: ${({ theme }) => theme?.linkResources?.item?.color || '#196ECF'};
    text-transform: capitalize;
    font-weight: 500;

    padding: ${({ theme }) => theme?.linkResources?.item?.padding || '15px 0px'};
    font-size: ${({ theme }) => theme?.linkResources?.item?.fontSize || '15px'};
    color: ${({ theme }) => theme?.linkResources?.item?.color || '#196ECF'};
    border-bottom: ${({ theme }) => theme?.linkResources?.item?.separator || 'none'};
  }

  &:last-child {
    span {
      border-bottom: ${({ hideDescription, theme }) =>
        hideDescription ? 'none' : theme?.linkResources?.item?.separator || 'none'};
    }
  }

}
`;
export const ResourceItem = ({ resource = {}, hideDescription = false }) => (
  <StyledResourceItem
    className="resource-item"
    key={resource?.id || resource?._id}
    href={resource?.asset?.url || resource?.asset || resource?.link}
    title={resource?.title}
    target="_blank"
    download={!!resource?.asset?.url}
    // data-clatter-event={JSON.stringify({
    //   type: 'Resource Click',
    //   data: {
    //     name: resource.title,
    //   },
    // })}
  >
    <span>{resource?.title}</span>
    {!!resource?.description && !hideDescription && <p>{resource?.description}</p>}
  </StyledResourceItem>
);
