import React from 'react';
import styled from 'styled-components';

const StyledCircleButton = styled.button`
  border: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;

  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 2px solid black;
    border-radius: 100%;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  svg {
    transition: all 0.2s ease-in-out;
    font-size: 1.7rem;
  }

  &:hover {
    box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.4);

    &:after {
      width: 23px;
      height: 23px;
      opacity: 1;
    }

    svg {
      transform: rotateZ(360deg);
      font-size: 1.3rem;
    }
  }
`;

export default StyledCircleButton;
