import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, NavLink, useNavigate } from 'react-router-dom';
import { Logout as LogoutIcon, Lock as LockIcon } from '@mui/icons-material';
import styled from 'styled-components';
import { useAuth, useNotices, usersApi } from '@clatter/platform'; // FIXME: circular deps!!!
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import Sidebar from '../Sidebar/Sidebar';
import UserProfileIcon from '../UserProfileIcon/UserProfileIcon';

const StyledPageHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  height: ${({ theme }) => theme.pageHeader.height};
  background-color: ${({ theme }) => theme.pageHeader.backgroundColor};
  color: ${({ theme }) => theme.pageHeader.color};
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 8px 16px;

  .logo {
    display: block;
    height: ${({ theme }) => theme.pageHeader.logo.height};
    padding: 8px;

    img {
      display: block;
      height: 100%;
    }
  }

  .page-header-content {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    display: flex;
    align-items: center;

    .logged-user {
      padding: 0 12px;
    }
  }
`;

const PageHeader = ({
  children,
  logoUrl,
  homeUrl,
  menuItems = [],
  toolItems = [],
}) => {
  const navigate = useNavigate();
  const { addNotice } = useNotices();
  const [isProfileDropDownOpen, setProfileDropDownOpen] = useState(false);
  const { activeUser, isLoading, isAuthenticated, error } = useAuth();

  const toggleUserProfileDropdown = () =>
    setProfileDropDownOpen(!isProfileDropDownOpen);

  if (isLoading) {
    return <Loader />;
  }
  // this is really a fatal error in config or
  // build. I'm not sure what you would do to
  // recover. Let's at least make it obvious that
  // this happened.
  if (error) {
    console.error(`Failed to load authorization ${error.message}`);
    return <div>Failed to load authorization {error.message}</div>;
  }

  const onPasswordChangeClick = async () => {
    try {
      setProfileDropDownOpen(false);
      await usersApi.resetPassword(activeUser.email);
      addNotice({
        message: `We've just sent you an email to reset your password to ${activeUser.email}`,
        type: 'success',
        title: 'Success',
      });
    } catch (error) {
      addNotice({
        message: `Unable to reset password. Reason: ${error.response.data?.error?.message}`,
        type: 'error',
        title: 'Error',
      });
    }
  };

  const profileDropDownOptions = [
    {
      name: 'Change Password',
      icon: <LockIcon />,
      clickEvent: onPasswordChangeClick,
    },
    {
      name: 'Logout',
      icon: <LogoutIcon />,
      link: generatePath('/logout'),
    },
  ];

  return (
    <StyledPageHeader>
      <Sidebar menuItems={menuItems} toolItems={toolItems} />
      <NavLink className="logo" to={homeUrl}>
        <img src={logoUrl} alt="Logo" />
      </NavLink>
      <div className="page-header-content">
        {children}
        {isAuthenticated ? (
          <>
            <div className="logged-user">{activeUser?.email}</div>
            <UserProfileIcon
              testId="user-circle"
              user={activeUser}
              handleUserIconClick={toggleUserProfileDropdown}
              isOpen={isProfileDropDownOpen}
              dropdownOptions={profileDropDownOptions}
            />
          </>
        ) : (
          <Button onClick={() => navigate('/')}>Login</Button>
        )}
      </div>
    </StyledPageHeader>
  );
};

PageHeader.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  homeUrl: PropTypes.string.isRequired,
  menuItems: Sidebar.propTypes.menuItems,
  toolItems: Sidebar.propTypes.toolItems,
};

export default PageHeader;
