import React from 'react';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { useGridApiContext } from '@mui/x-data-grid';
import IconButton from '../IconButton/IconButton';
import { handleExportAsCSV } from '@clatter/platform';

const DataGridExportButton = ({ exportedFileName, customTooltip, ...props }) => {
  const apiRef = useGridApiContext();
  const options = {
    utf8WithBom: true,
  };
  const tooltip = customTooltip || 'Export Selected';

  return (
    <IconButton
      onClick={() =>
        handleExportAsCSV({
          options: options,
          apiRef: apiRef,
          exportedFileName: exportedFileName,
        })
      }
      {...props}
      tooltip={tooltip}
    >
      <CloudDownloadIcon />
    </IconButton>
  );
};

export default DataGridExportButton;
