import { Grid, Stack } from '@mui/material';
import styled from 'styled-components';
import { Card, CardContent } from '../Card';
import Typography from '../Typography/Typography';

const StyledBaseCard = styled(Card)`
  height: 100%;
  ${({ useFlex }) => !useFlex && `max-width: 280px`};
  ${({ disabled }) => disabled && `opacity: .25`};
  ${({ borderColor }) =>
    borderColor && `border-left: 5px solid ${borderColor}`};
`;

const StyledBaseCardHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

const StyledValue = styled.div`
  font-size: 2.3rem;
  color: #495057;
  line-height: 1.2;
  letter-spacing: -2px;
  font-weight: 400;
`;

const BoxCard = ({
  title,
  value,
  useFlex = false,
  disabled = false,
  borderColor = null,
  children
}) => (
  <StyledBaseCard
    useFlex={useFlex}
    disabled={disabled}
    borderColor={borderColor}
  >
    <Stack px={3} py={2.5} direction="column" justifyContent="space-between" minHeight={130}>
      <StyledBaseCardHeader>
        <Typography variant="h4" component="span">
          {title}
        </Typography>
      </StyledBaseCardHeader>
      {
        children ? children : <StyledValue>{value}</StyledValue>
      }
    </Stack>
  </StyledBaseCard>
);

export default BoxCard;
