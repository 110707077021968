import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const eventCountsApi = {
  getEventCounts: ({
    eventType = null,
    startDate = null,
    endDate = null,
    tool = null,
    userGroup = null,
    templateId = null,
    contentRepositoryId = null,
    downloadSource = null,
    connection = null,
    groupBy = [],
    groupByAdditionalFields = [],
    sortBy = null,
    sortOrder = null,
    uniqueBy = null,
    responseMode = 'counts',
  }) => {
    const params = {};

    if (eventType) {
      params.event_type = eventType;
    }

    if (startDate) {
      params.start_date = startDate;
    }

    if (endDate) {
      params.end_date = endDate;
    }

    if (tool) {
      params.tool = tool;
    }

    if (userGroup) {
      params.user_group = userGroup;
    }

    if (templateId) {
      params.template_id = templateId;
    }

    if (contentRepositoryId) {
      params.content_repository = contentRepositoryId;
    }

    if (downloadSource) {
      params.download_source = downloadSource;
    }

    if (connection) {
      params.connection = connection;
    }

    if (groupBy && groupBy.length) {
      params.group_by = groupBy;
    }

    if (groupByAdditionalFields && groupByAdditionalFields.length) {
      params.group_by_additional_fields = groupByAdditionalFields;
    }

    if (sortBy) {
      params.sort_by = sortBy;
    }

    if (sortOrder) {
      params.sort_order = sortOrder;
    }

    if (uniqueBy) {
      params.unique_by = uniqueBy;
    }

    params.response_mode = responseMode;

    return getAxiosApiV2WithAuthorization({
      url: '/event-counts',
      params: params,
    });
  },
};

export default eventCountsApi;
