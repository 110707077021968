import React, { useEffect } from 'react';
import { useAuth } from '@clatter/platform';
import { Loader } from '@clatter/ui';

const LandingPage = () => {
  const { isAuthenticated } = useAuth();
  const rootDomain = process.env.NX_APP_ROOT_DOMAIN;
  const currentURL = window.location.origin;

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.replace(rootDomain + `/?redirectTo=${currentURL}`);
    }
  }, [isAuthenticated, currentURL, rootDomain]);

  return <Loader />;
};

export default LandingPage;
