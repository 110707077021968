import React from 'react';
import styled from 'styled-components';
import { NoticesList } from '@clatter/ui';

const StyledPageContent = styled.div`
  min-width: 300px;
  max-width: 1400px;
  margin: 0 auto;
  padding: ${({ padding }) => padding || '32px 16px;'};
  min-height: ${({ minHeight }) => minHeight || 'calc(100vh - 64px)'};
`;

const StyledWidePageContent = styled.div`
  min-width: 300px;
  max-width: calc(100vw - 80px);
  margin: 0 auto;
  padding: 32px 16px;
  min-height: calc(100vh - 64px);
`;

const PageContent = ({ children, minHeight, padding, widePageContent = false }) => (
  <>
    {widePageContent ? (
      <StyledWidePageContent>
        <NoticesList />
        {children}
      </StyledWidePageContent>
    ) : (
      <StyledPageContent minHeight={minHeight} padding={padding}>
        <NoticesList />
        {children}
      </StyledPageContent>
    )}
  </>
);

export default PageContent;
