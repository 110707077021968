import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import classNames from 'classnames';
import { VideoGalleryIframe, VideoPreviewDialog } from "@clatter/ui";

const StyledVideoContainer = styled.div`
  .video-gallery-item {
    margin-bottom: auto;

    .video {
      width: ${(props) => `${props?.videoSize?.width}px`};
      height: ${(props) => `${props?.videoSize?.height}px`};
      position: relative;
      background-color: #000;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .preview-video-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 18px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.9);
        fill: #fff;
        width: 48px;
        height: 36px;
      }
    }

    .video-details {
      padding: ${({ theme }) => theme.videoGallery?.item.padding || '4px'};
      max-width: ${(props) => `${props.videoSize.width}px`};

      .video-title {
        font-family: ${({ theme }) => theme.videoGallery?.item.title.fontFamily || 'sans-serif, Arial'};
        font-weight: ${({ theme }) => theme.videoGallery?.item.title.fontWeight || 700};
        font-size: ${({ theme }) => theme.videoGallery?.item.title.fontSize || '19px'};
        line-height: ${({ theme }) => theme.videoGallery?.item.title.lineHeight || '1.3'};
        color: ${({ theme }) => theme.videoGallery?.item.title.color || '#002677'};
        padding: ${({ theme }) => theme.videoGallery?.item.title.padding || '4px 0'};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title-link {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .video-description {
        font-size: ${({ theme }) => theme.videoGallery?.item.description.fontSize || '12px'};
        color: ${({ theme }) => theme.videoGallery?.item.description.color || 'inherit'};
      }

      @media (max-width: 1400px) {
        .video-title {
          font-size: 1.4em;
        }
      }
    }

    @media (min-width: 350px) {
      margin: auto;
      margin-top: 0;
    }
  }
`;
export const VideoGalleryItem = ({
  video,
  videoWidth = 200,
  urlProperty = 'link',
  videoHeight: customVideoHeight,
  showDescription = true,
  showUsageCount = false,
  videoAspectRatio = '16/9',
}) => {
  const [videoSize, setVideoSize] = useState({ width: videoWidth, height: 112.5 });
  const [previewVideo, setPreviewVideo] = useState(null);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const [widthRatio, heightRatio] = videoAspectRatio.split('/');
    const videoHeight = customVideoHeight
      ? customVideoHeight
      : parseInt((videoWidth / Number(widthRatio)) * Number(heightRatio));
    setVideoSize({ width: videoWidth, height: videoHeight });
  }, [videoWidth, videoAspectRatio]);

  const handleVideoClick = (event) => {
    if (!event) {
      // clicked outside video container, closing dialog window
      return setPreviewVideo(null);
    }
    const clickedItemId = event.currentTarget.dataset.id;
    // const nextPreviewVideo = videos.find((video) => video.id === clickedItemId) || null;
    // setPreviewVideo(nextPreviewVideo);

    if (previewVideo) {
      setPreviewVideo(null);
      return;
    }
    setPreviewVideo(video);
  };

  const handleTitleClick = () => {
    if (!video.pathname) {
      return;
    }
    navigate(video.pathname);
  };

  return (
    <StyledVideoContainer videoSize={videoSize}>
      <div className="video-gallery-item" key={video.id}>
        <div className="video">
          <div
            data-id={video.id}
            data-video-name={video.title} // required by GA4 do not remove
            onClick={handleVideoClick}
            className="preview-video-button"
            data-clatter-event={JSON.stringify({
              type: 'Video Click',
              data: {
                name: video.title,
              },
            })}
          >
            <PlayArrowIcon />
          </div>
          <VideoGalleryIframe videoUrl={video?.[urlProperty]} />
        </div>
        <div className="video-details">
          <div
            className={classNames('video-title', { 'title-link': !!video?.pathname })}
            onClick={() => handleTitleClick()}
          >
            {video.title}
          </div>
          {showDescription && (
            <div className="video-description">{video.description || '(description not provided)'}</div>
          )}

          {showUsageCount && video.usage_count && (
            <div>
              Usage count: {video.usage_count}
            </div>
          )}
        </div>
        <VideoPreviewDialog
          title={previewVideo?.title}
          videoUrl={previewVideo?.link}
          open={!!previewVideo}
          onCloseDialog={handleVideoClick}
        />
      </div>
    </StyledVideoContainer>
  );
};
