import Grid2 from '@mui/material/Unstable_Grid2';

export const FiltersSectionWrapper = ({ components }) => {
  if (components?.length === 0) {
    return;
  }

  return (
    <Grid2 container xs={12} md={8} spacing={1}>
      {components?.map((item, index) => (
        <Grid2 xs={12} key={`${index}-filters-section-wrapper`}>
          {item}
        </Grid2>
      ))}
    </Grid2>
  );
};
