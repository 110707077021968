import React from 'react';
import styled from 'styled-components';

const StyledList = styled.div`
  background-color: #fff;

  &.nested-list {
    padding-left: 8px;
  }
`;

const List = ({ children, nested = false }) => (
  <StyledList className={`${nested ? 'nested-list' : ''}`}>
    {children}
  </StyledList>
);

export default List;
