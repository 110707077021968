import React from 'react';
import styled from 'styled-components';
import { defaultBorderColor, defaultBorderRadius } from '../../config/theme';

const StyledStepCard = styled.div`
  background-color: #fff;
  border: 1px solid ${defaultBorderColor};
  border-radius: ${defaultBorderRadius};
  margin-bottom: 24px;
`;

const StepCard = ({ children }) => <StyledStepCard>{children}</StyledStepCard>;

export default StepCard;
