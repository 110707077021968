import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import { Button, buttonVariants, SelectControl } from '@clatter/ui';
import { useDeepCompareEffect, useDeepCompareMemo, useRoutesState } from '@clatter/platform';
import {isEqual} from "lodash";

const AddInputButton = ({ label, onChange, disabled }) => {
  return (
    <Button variant={buttonVariants.clear} disabled={disabled} onClick={onChange}>
      {label}
    </Button>
  );
};

export const REPORTS_GROUPS_SECTION_KEY = 'groupBy';

export const ReportGroupsSection = ({ label = 'Group By', inputs = [], selectedFilterBy, onChange, isLoading }) => {
  const { setRouteStateProperty, getRouteStateProperty } = useRoutesState();
  const [selected, setSelected] = useState(() => getRouteStateProperty(REPORTS_GROUPS_SECTION_KEY) || [null]); // [null] is needed to show at least one input with a placeholder

  //region COMPUTED
  const selectedValues = useDeepCompareMemo(
    () => (selected || []).map((item) => item?.value)?.filter((item) => item),
    [selected],
  );

  const enabledInputs = useMemo(() => {
    return (inputs || [])?.filter((inputItem) => inputItem?.hasOwnProperty('isEnabled') && inputItem?.isEnabled);
  }, [inputs, selectedFilterBy]);

  const enabledInputsValues = useDeepCompareMemo(
    () => (enabledInputs || [])?.map((item) => item?.value),
    [enabledInputs],
  );

  const getSelectorOptions = useDeepCompareMemo(() => {
    return inputs.filter(
      (item) => (enabledInputsValues || [])?.includes(item?.value) && !selectedValues?.includes(item?.value),
    );
  }, [inputs, selectedValues]);
  //endregion

  //region METHODS
  const handleAddGroupBy = (index) => {
    // return if there is already a value in target index
    if (selected[index + 1]) {
      return;
    }
    const nextInput = enabledInputs.filter((item) => !selectedValues.includes(item?.value))[0];
    const updated = [...selected];
    updated[index + 1] = nextInput;
    setSelected(updated);
  };

  // remove group by values if picked filters
  // are excluding grouping
  useDeepCompareEffect(() => {
    const updated = selected?.map((item) => {
      if(!enabledInputsValues.includes(item?.value)) {
        return null;
      }
      return item;
    })

    if(isEqual(selected, updated)) {
      return;
    }

    setSelected(updated);
  }, [enabledInputsValues, selected])

  const handleSelectChange = (value, index) => {
    const updated = [...selected];
    updated[index] = value;
    setSelected(updated);
  };
  //endregion

  //region EFFECTS
  // persist inputs state
  useDeepCompareEffect(() => {
    setRouteStateProperty({ [REPORTS_GROUPS_SECTION_KEY]: selected });
  }, [selected]);

  // emit selected values for use in the parent component
  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);
  //endregion

  // hide section when no group by inputs are available
  if (!inputs.length) {
    return null;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Divider textAlign="left">{label}</Divider>
      <Stack direction="column" spacing={2}>
        {selected?.map((item, index) => {
          if (index > 0 && !item) {
            return null;
          }

          return (
            <Stack direction="row" spacing={2} key={`${index}-selector`} alignItems="center">
              <SelectControl
                menuPosition="absolute"
                onChange={(value) => handleSelectChange(value, index)}
                value={selected[index]}
                options={getSelectorOptions}
                placeholder="Select group..."
                isDisabled={isLoading || enabledInputs?.length === 0}
                isClearable
              />
              {selected[index] && enabledInputs?.length > 1 && index < enabledInputs?.length - 1 && (
                <AddInputButton
                  disabled={!!selected[index + 1]}
                  onChange={() => handleAddGroupBy(index)}
                  label="And then by"
                />
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
