import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const StyledCircularProgress = styled(CircularProgress)`
  svg {
    color: ${({ theme }) => theme.palette.primaryColor};
  }
`;

const CircularLoader = () => {
  return <StyledCircularProgress />;
};

export default CircularLoader;
