import styled from "styled-components";

export const StyledControlsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 50px;
  margin-top: -15px;
  margin-bottom: 8px;
  gap: 40px;
`;
