import getAxiosApiV2WithAuthorization from '../services/axiosApiV2Factory';

const exportImportApi = {
  exportData: ({ url, params, contentType = 'application/zip' }) =>
    getAxiosApiV2WithAuthorization({
      url: url,
      method: 'GET',
      headers: {
        'Content-Type': contentType,
      },
      data: {}, // hack fo axios => https://github.com/axios/axios/issues/86#issuecomment-139638284
      responseType: 'blob',
      params: params
    }),
  importData: ({ url, file }) => {
    const formData = new FormData();

    formData.append('import_file', file);

    return getAxiosApiV2WithAuthorization({
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  },
};

export default exportImportApi;
